import axios from "axios";

export const fetchFilterData = async () => {
    try {
        const url = `/api/get-filter-data`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCountries = async () => {
    try {
        const url = `/api/countries`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchTimeZones = async () => {
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchFilterData2 = async () => {
    try {
        const url = `/api/get-filter-data-2`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const validateTimeSlot = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/validate-time-slots`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createDistribution = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/distributions`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.log(error.data.error);
        throw new Error(error.data.error);
        // return null;
    }
};

export const updateDistribution = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/distributions`;
        const response = await axios.put(url, payload);
        return response.data;
    } catch (error) {
        console.log(error.data.error);
        throw new Error(error.data.error);
        // return null;
    }
};

export const fetchDistributionById = async (id) => {
    try {
        const url = `/api/distributions/${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return {};
    }
};

export const deleteDistributionRequest = async (id) => {
    try {
        const url = `/api/distributions/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchDistributions = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/distributions`;
        const response = await axios.get(url, { params: payload });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getPublishersForFilter = () => {
    return axios.get('/api/publishers')
        .then(response => {
            if (!response) {
                console.error('No response received from publishers API');
                return { data: [] };
            }
            return response;
        })
        .catch(error => {
            console.error('Error fetching publishers data:', {
                message: error.message,
                config: error.config,
                response: error.response
            });
            throw error;
        });
};

<template>
    <v-dialog persistent v-model="dialog" transition="dialog-bottom-transition"  >
        <v-card v-if="page === 1" class="position-relative " style="width:100%;max-width:60rem;margin:0 auto" :style="$vuetify.display.xlAndUp ? 'height:75vh !important;':'height:90vh !important;'">

            <v-toolbar>
                <v-toolbar-title>New Distribution</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="$emit('close')"></v-btn>
                </v-toolbar-items>

            </v-toolbar>
            <!-- parameters form -->
            <v-container fluid v-if="showParameterForm" class="w-100 h-100 p-0  position-absolute bottom-0 left-0" style="z-index:4;background:rgba(var(--v-theme-on-surface),0.32)">
                <v-list lines="two" subheader class="bg-white  w-100">
                    <v-container fluid class="text-uppercase w-100">
                        <v-col class="py-0 w-100 d-flex gap-2 align-items-center justify-content-between">
                            <div>
                                <v-icon>mdi-tune</v-icon>
                                Add Parameter
                            </div>
                            <v-btn icon="mdi-close" variant="text"  @click="showParameterForm=false"></v-btn>
                        </v-col>
                    </v-container>
                    <v-list-item >
                        <form @submit.prevent="addParameter">
                            <v-col>
                                <div
                                    class="d-flex flex-wrap flex-md-nowrap gap-4 justify-center"
                                >
                                    <v-autocomplete
                                        required
                                        label="Parameter"
                                        class="w-100"
                                        v-model="parameterLabel"
                                        :items="visibleParameterLabels"
                                        placeholder="Select a parameter"
                                        item-title="name"
                                        return-object
                                        variant="outlined"
                                        hide-details
                                        clearable
                                        density="compact"
                                    >
                                    </v-autocomplete>
                                    <v-autocomplete
                                        required
                                        label="Value"
                                        class="w-100"
                                        v-model="parameterValue"
                                        :items="parameterValues"
                                        placeholder="Select a value"
                                        item-title="name"
                                        return-object
                                        variant="outlined"
                                        hide-details
                                        clearable
                                        density="compact"
                                    >
                                    </v-autocomplete>


                                </div>
                            </v-col>
                            <v-col class="d-flex gap-2">
                                <v-spacer></v-spacer>
                                <v-btn
                                    prepend-icon="mdi-plus"
                                    :disabled="!parameterLabel || !parameterValue"
                                    type="submit"
                                    variant="flat"
                                    color="success">Add</v-btn>
                            </v-col>
                        </form>

                    </v-list-item>
                </v-list>
            </v-container>

            <!-- Overwrite form -->
            <v-container fluid v-if="showOverwriteForm" class="w-100 p-0  position-absolute bottom-0 left-0" style="z-index:4;height:100%; background:rgba(var(--v-theme-on-surface),0.32)">
                <v-list lines="two" subheader class="bg-white">
                    <v-container fluid class="text-uppercase w-100">
                        <v-col class="py-0 w-100 d-flex gap-2 align-items-center justify-content-between">
                            <div>
                                <v-icon>mdi-tune</v-icon>
                                Add Lead Data Overwrite
                            </div>
                            <v-btn icon="mdi-close" variant="text"  @click="showOverwriteForm=false"></v-btn>
                        </v-col>
                    </v-container>
                    <v-list-item >
                        <form @submit.prevent="addOverwrite">
                            <v-col>
                                <div
                                    class="d-flex flex-wrap flex-md-nowrap gap-4 justify-center"
                                >
                                    <v-autocomplete
                                        label="Parameter"
                                        class="w-100"
                                        v-model="overwriteLabel"
                                        :items="visibleOverwriteLabels"
                                        placeholder="Select a parameter"
                                        item-title="name"
                                        return-object
                                        variant="outlined"
                                        hide-details
                                        clearable
                                        density="compact"
                                    >
                                    </v-autocomplete>
                                    <v-combobox
                                        class="w-100"
                                        v-model="overwriteValue"
                                        :items="overwriteValues"
                                        label="Values"
                                        placeholder="Select a value"
                                        item-title="name"
                                        multiple
                                        return-object
                                        variant="outlined"
                                        hide-details
                                        clearable
                                        density="compact"
                                    >
                                        <!-- :rules="[
                                                (value) =>
                                                    !!value.length ||
                                                    'Please select at least one status',
                                            ]" -->
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip
                                                small
                                                @click="overwriteValue.splice(index, 1)"
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>

                                </div>
                            </v-col>
                            <v-col class="d-flex gap-2">
                                <v-spacer></v-spacer>
                                <v-btn
                                    prepend-icon="mdi-plus"
                                    :disabled="!overwriteLabel || overwriteValue?.length <= 0"
                                    type="submit"
                                    variant="flat"
                                    color="success">Add</v-btn>
                            </v-col>
                        </form>

                    </v-list-item>
                </v-list>
            </v-container>

            <v-list lines="two" subheader >

                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-cog</v-icon>
                    General Information</v-list-subheader
                >
                <v-list-item>
                    <v-col>
                        <v-text-field
                            v-model="label"
                            label="Label"
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-combobox
                            v-model="tags"
                            :items="[]"
                            label="Tags"
                            multiple
                            return-object
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip
                                    small
                                    @click="tags.splice(index, 1)"
                                    close
                                >
                                    {{ item.title }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-cog</v-icon>
                    Method</v-list-subheader
                >
                <v-list-item>
                    <v-radio-group
                        v-model="method"
                        inline
                        label="Method"
                        hide-details
                    >
                        <v-radio label="SLOTS" value="slot"></v-radio>
                        <v-radio label="CHANCE" value="chance"></v-radio>
                        <v-radio label="OPTIMIZE" value="optimize"></v-radio>
                    </v-radio-group>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-clock</v-icon>
                    Hours
                </v-list-subheader>
                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="timeZone"
                            :items="timeZones"
                            placeholder="Select a timezone"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                            :loading="isLoadingTimeZones"
                        >
                            <template v-slot:prepend-inner>
                                <v-progress-circular
                                    v-if="isLoadingTimeZones"
                                    indeterminate
                                    size="20"
                                    color="primary"
                                ></v-progress-circular>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            class="ml-n2"
                            v-model="traffic"
                            label="Traffic (Reject leads outside opening hours)"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-earth</v-icon>
                    Country
                </v-list-subheader>
                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="country"
                            :items="countries"
                            placeholder="Select a country"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Publisher
                </v-list-subheader>
                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="selectedPublisher"
                            :items="publishers"
                            placeholder="Select publisher"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            density="compact"
                            hide-details
                            clearable
                            :loading="isLoadingPublishers"
                            :no-data-text="isLoadingPublishers ? 'Loading data...' : 'No data available'"
                            return-object
                        >
                            <!--<template v-slot:selection>
                                <span>{{ selectedPublisher?.name }}</span>
                            </template>-->
                            <template v-slot:prepend-inner>
                                <v-progress-circular
                                    v-if="isLoadingPublishers"
                                    indeterminate
                                    size="20"
                                    width="2"
                                    color="primary"
                                />
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <!-- Parameters -->
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Parameters
                </v-list-subheader>
                <v-list-item>
                    <v-col>
                        <v-btn prepend-icon="mdi-plus" color="primary " variant="flat" @click="showParameterForm=true">
                            Add Parameter
                        </v-btn>
                    </v-col>
                    <v-col v-if="selectedParameters.length > 0">
                        <v-list>
                            <v-list-item
                                v-for="(parameter, index) in selectedParameters"
                                :key="`parameter-${index}`"
                                class="border"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        parameter.label.name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        parameter.value.name
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <StandardBtn
                                        type="delete"
                                        variant="text"
                                        @click="removeParameter(index)"
                                    />
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col v-else class="text-center"> No parameters </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Lead Data Overwrite
                </v-list-subheader>
                <v-list-item>
                    <v-col>
                        <v-btn prepend-icon="mdi-plus" color="primary " variant="flat" @click="showOverwriteForm=true">
                            Add Lead Data Overwrite
                        </v-btn>
                    </v-col>
                    <v-col v-if="selectedOverwrites.length > 0">
                        <v-list>
                            <v-list-item
                                v-for="(overwrite, index) in selectedOverwrites"
                                :key="`overwrite-${index}`"
                                class="border"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        overwrite.label.name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        overwrite.value.join(", ")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <StandardBtn
                                        type="delete"
                                        variant="text"
                                        @click="removeOverwrite(index)"
                                    />
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col v-else class="text-center">
                        No overwriteLabels
                    </v-col>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-btn @click="togglePage" color="primary">Next</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="page === 2"  style="height:97vh !important;width:100% !important;margin:0 auto;">
            <v-toolbar>
                <v-toolbar-title>Distribution Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="$emit('close')"></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-list class="p-0 pb-14">
                <v-tabs v-model="tab" bg-color="primary">
                    <!-- {{ hasInvalidPercentages }} -->
                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="`tab-${index}`"
                        :text="`${timeSlots[index]?.name ? timeSlots[index]?.name : `Timeslot ${index + 1}`}`"
                        :value="`tab-${index}`"
                    ></v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item
                        v-for="(tab, i) in tabs"
                        :key="`tab-content-${i}`"
                        :value="`tab-${i}`"
                    >
                        <v-card>
                            <div
                                class="d-flex flex-wrap flex-md-nowrap align-center gap-2 justify-center justify-md-end m-1 m-md-4"
                            >
                                <!-- btns - add new group, remove all groups -->
                                <v-btn
                                    icon
                                    @click="openTimeSlotDialog(i)"
                                    variant="outlined"
                                >
                                    <v-icon>mdi-clock</v-icon>
                                </v-btn>
                                <!-- add timeslot to left & right -->
                                <v-btn
                                    icon
                                    @click="addTabToLeft(i)"
                                    variant="outlined"
                                    color="primary"
                                >
                                    <v-icon style="transform: rotateY(180deg)"
                                        >mdi-clock-start</v-icon
                                    >
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="addTabToRight(i)"
                                    variant="outlined"
                                    color="primary"
                                >
                                    <v-icon>mdi-clock-start</v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    prepend-icon="mdi-plus"
                                    text="Timeslot to right"
                                    @click="addTabToRight(i)"
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                ></v-btn> -->
                                <!-- <v-btn
                                    prepend-icon="mdi-delete"
                                    text="Timeslot"
                                    size="small"
                                    color="danger"
                                    variant="outlined"
                                    @click="removeTab(i)"
                                ></v-btn> -->
                                <v-btn
                                    icon
                                    @click="removeTab(i)"
                                    variant="outlined"
                                    color="danger"
                                >
                                    <v-icon>mdi-delete-clock</v-icon>
                                </v-btn>
                                <StandardBtn
                                    type="create"
                                    text
                                    @click="addGroup(i)"
                                >
                                    Group
                                </StandardBtn>
                                <StandardBtn
                                    type="delete"
                                    text
                                    @click="removeAllGroups(i)"
                                >
                                    All
                                </StandardBtn>
                            </div>
                            <template v-if="tabs[i].length <= 0">
                                <v-card-text class="text-center text-caption">
                                    Please add groups to the timeslot
                                </v-card-text>
                            </template>
                            <v-list v-else>
                                <draggable
                                    v-model="tabs[i]"
                                    :group="{ name: 'groups' }"
                                    item-key="id"
                                    handle=".drag-handle"
                                    :animation="200"
                                >
                                    <template #item="{ element: group, index: j }">
                                        <v-list-item class="mb-2">
                                            <v-card
                                                elevation="0"
                                                class="border w-100 drag-handle"
                                            >
                                                <v-card-actions
                                                    class="d-flex flex-wrap flex-md-nowrap align-center gap-2 justify-space-between py-2"
                                                >
                                                    <div class="w-100">
                                                        <v-card-title
                                                            class="d-flex justify-center justify-md-start align-center"
                                                        >
                                                            <v-btn
                                                                icon
                                                                variant="text"
                                                                color="grey"
                                                            >
                                                                <v-icon>mdi-drag</v-icon>
                                                            </v-btn>

                                                            <v-text-field
                                                                v-model="group.name"
                                                                label="Group Name"
                                                                variant="outlined"
                                                                density="compact"
                                                                class="d-inline-block"
                                                                hide-details
                                                                max-width="200px"
                                                            ></v-text-field>
                                                            &nbsp;
                                                            <v-chip
                                                                v-if="
                                                                    calculateGroupShare(
                                                                        group,
                                                                    ) === 100
                                                                "
                                                                color="success"
                                                                label
                                                            >
                                                                <v-icon left
                                                                    >mdi-check-bold</v-icon
                                                                >
                                                                &nbsp;
                                                                {{ calculateGroupShare(group) }}
                                                            </v-chip>
                                                            <v-chip v-else color="error" label>
                                                                <v-icon left>mdi-alert</v-icon>
                                                                &nbsp;
                                                                {{ calculateGroupShare(group) }}
                                                            </v-chip>
                                                        </v-card-title>
                                                    </div>
                                                    <div
                                                        class="d-flex gap-2 align-center justify-center justify-md-end flex-wrap w-100 mr-0 mr-md-2"
                                                    >
                                                        <StandardBtn
                                                            type="create"
                                                            text
                                                            @click="addBrand(i, j)"
                                                        >
                                                            Advertiser
                                                        </StandardBtn>
                                                        <v-btn
                                                                icon
                                                                variant="text"
                                                                class="mr-2"
                                                                color="grey"
                                                                @click="moveGroupUp(i, j)"
                                                                :disabled="j === 0"
                                                            >
                                                                <v-icon>mdi-chevron-up</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                icon
                                                                variant="text"
                                                                class="mr-2"
                                                                color="grey"
                                                                @click="moveGroupDown(i, j)"
                                                                :disabled="j === tabs[i].length - 1"
                                                            >
                                                                <v-icon>mdi-chevron-down</v-icon>
                                                            </v-btn>
                                                        <StandardBtn
                                                            type="delete"
                                                            size="x-small"
                                                            @click="removeGroup(i, j)"
                                                        />
                                                    </div>
                                                </v-card-actions>
                                                <v-card-text>
                                                    <div class="brand-container">
                                                        <draggable
                                                            v-model="group.brands"
                                                            :group="{ name: 'brands' }"
                                                            item-key="id"
                                                            handle=".drag-handle"
                                                            :animation="200"
                                                            @change="handleBrandMove($event, i, j)"
                                                        >
                                                            <template #item="{ element: brand, index: k }">
                                                                <div
                                                                    class="d-flex flex-wrap flex-md-nowrap align-center gap-2 border p-3 drag-handle"
                                                                >
                                                                    <div class="d-flex align-center gap-2" style="width: 300px">
                                                                        <v-btn
                                                                            icon
                                                                            variant="text"
                                                                            class="drag-handle"
                                                                            color="grey"
                                                                        >
                                                                            <v-icon>mdi-drag</v-icon>
                                                                        </v-btn>

                                                                        <v-autocomplete
                                                                            v-model="brand.data"
                                                                            :items="brands"
                                                                            placeholder="Select a brand"
                                                                            item-title="name"
                                                                            return-object
                                                                            variant="outlined"
                                                                            hide-details
                                                                            clearable
                                                                            density="compact"
                                                                            :rules="[
                                                                                (value) =>
                                                                                    !!value ||
                                                                                    'Please select a valid brand',
                                                                            ]"
                                                                        >
                                                                            <template v-slot:label>
                                                                                <span>Advertiser</span>
                                                                            </template>
                                                                            <template
                                                                                v-slot:selection="{ item }"
                                                                            >
                                                                                <v-chip small>
                                                                                    <span>{{
                                                                                        item.title
                                                                                    }}</span>
                                                                                </v-chip>
                                                                            </template>
                                                                        </v-autocomplete>
                                                                    </div>

                                                                    <!-- Center the slider section -->
                                                                    <div class="d-flex align-center justify-center gap-2" style="flex: 1">
                                                                        <v-slider
                                                                            v-model="brand.percentage"
                                                                            :max="100"
                                                                            :min="0"
                                                                            :step="1"
                                                                            thumb-label
                                                                            hide-details
                                                                            color="primary"
                                                                            style="max-width: 200px"
                                                                        >
                                                                        </v-slider>
                                                                        <div class="d-flex align-center" style="min-width: 85px">
                                                                            <v-text-field
                                                                                v-model="brand.percentage"
                                                                                density="compact"
                                                                                style="max-width: 75px"
                                                                                :max="100"
                                                                                :min="0"
                                                                                type="number"
                                                                                hide-details
                                                                                variant="outlined"
                                                                            ></v-text-field>
                                                                            <span class="ml-1">%</span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="d-flex align-center gap-1" style="width: 120px">
                                                                        <v-btn
                                                                            icon
                                                                            variant="text"
                                                                            color="grey"
                                                                            @click="moveBrandUp(i, j, k)"
                                                                            :disabled="k === 0"
                                                                        >
                                                                            <v-icon>mdi-chevron-up</v-icon>
                                                                        </v-btn>
                                                                        <v-btn
                                                                            icon
                                                                            variant="text"
                                                                            color="grey"
                                                                            @click="moveBrandDown(i, j, k)"
                                                                            :disabled="k === group.brands.length - 1"
                                                                        >
                                                                            <v-icon>mdi-chevron-down</v-icon>
                                                                        </v-btn>
                                                                        <StandardBtn
                                                                            type="delete"
                                                                            variant="text"
                                                                            @click="removeBrand(i, j, k)"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </draggable>
                                                        <div v-show="group.brands.length === 0" class="empty-group-placeholder">
                                                            Drop advertisers here
                                                        </div>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-list-item>
                                    </template>
                                </draggable>
                            </v-list>
                        </v-card>
                    </v-window-item>
                </v-window>
            </v-list>
            <v-card-actions
                class="position-absolute left-0 bottom-0 right-0 bg-white"
            >
                <div
                    v-if="createDistributionError"
                    class="text-danger d-flex align-center gap-1"
                >
                    <v-icon color="danger">mdi-alert</v-icon>
                    {{ createDistributionError }}
                </div>
                <v-btn @click="togglePage" color="primary">Previous</v-btn>
                <v-btn
                    @click="submit"
                    :disabled="hasInvalidPercentages"
                    color="primary"
                    >Submit</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="timeSlotDialog" persistent max-width="650">
        <v-card>
            <v-card-title> New Timeslot </v-card-title>
            <v-card-text>
                <v-autocomplete
                    v-model="timeZone"
                    :items="timeZones"
                    placeholder="Select a timezone"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    hide-details
                    disabled
                    density="compact"
                >
                </v-autocomplete>
                <v-checkbox
                    class="ml-n2"
                    v-model="traffic"
                    label="Traffic (Reject leads outside opening hours)"
                    color="primary"
                    hide-details
                    disabled
                ></v-checkbox>
                <v-text-field
                    v-model="timeSlots[timeSlotIndex].name"
                    label="Name"
                    variant="outlined"
                    hide-details
                    density="compact"
                    hint="Name that will be used to reference this timeslot"
                ></v-text-field>
                <div class="mt-4">
                    <div class="text-subtitle-1">Opening Hours</div>
                    <!-- It going to contain 7 rows for each day of the week, Each row will have a start time and end time in 24Hrs format also a checkbox to enable or disable the opening hours for that day, and we have an option to add multiple time slots for every day but time slot can be overlapping. -->
                    <div
                        v-for="(timeSlotDay, dayIndex) in timeSlots[
                            timeSlotIndex
                        ]['days']"
                        :key="timeSlotDay.id"
                        class="d-flex align-center justify-space-between flex-wrap border my-2 position-relative"
                    >
                        <div class="d-flex align-center flex-wrap">
                            <v-checkbox
                                v-model="timeSlotDay.checked"
                                :label="timeSlotDay.label"
                                color="primary"
                                hide-details
                                width="120px"
                            ></v-checkbox>
                            <StandardBtn
                                type="custom"
                                variant="text"
                                custom-icon="mdi-plus-circle"
                                v-if="timeSlotDay.checked"
                                @click="addStep(timeSlotDay)"
                            />
                            <!-- copy to all -->
                            <StandardBtn
                                type="copy"
                                variant="text"
                                text
                                v-if="
                                    timeSlotDay.checked && timeSlotDay.id == 1
                                "
                                @click="
                                    copyStepsToAll(timeSlotDay, timeSlotIndex)
                                "
                            >
                                Copy to all
                            </StandardBtn>
                            <StandardBtn
                                type="copy"
                                variant="text"
                                text
                                v-if="
                                    timeSlotDay.checked && timeSlotDay.id != 1
                                "
                                @click="
                                    copyPreviousStep(
                                        timeSlotDay,
                                        timeSlotIndex,
                                        dayIndex - 1,
                                    )
                                "
                            >
                                Copy previous
                            </StandardBtn>
                        </div>
                        <div
                            v-if="!timeSlotDay.checked"
                            class="mr-2 text-overline"
                        >
                            closed
                        </div>
                        <div
                            class="d-flex align-center"
                            v-if="timeSlotDay.checked"
                        >
                            <div
                                class="d-flex flex-column align-start align-md-end py-2"
                            >
                                <div
                                    v-for="(step, l) in timeSlotDay.steps"
                                    :key="l"
                                >
                                    <div class="pr-2 d-flex align-center">
                                        <StandardBtn
                                            v-if="timeSlotDay.steps.length > 1"
                                            type="delete"
                                            variant="text"
                                            @click="removeStep(timeSlotDay, l)"
                                        />
                                        <!-- <v-text-field
                                            style="width: 75px"
                                            v-model="step.start_time"
                                            :active="step.menu.start_time"
                                            readonly
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                        >
                                            <v-menu
                                                v-model="step.menu.start_time"
                                                :close-on-content-click="false"
                                                activator="parent"
                                                transition="scale-transition"
                                            >
                                                <v-time-picker
                                                    v-if="step.menu.start_time"
                                                    v-model="step.start_time"
                                                    full-width
                                                    format="24hr"
                                                ></v-time-picker>

                                            </v-menu>
                                        </v-text-field> -->
                                        <v-text-field
                                            v-model="step.start_time"
                                            style="min-width: 82px"
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                            placeholder="hh:mm"
                                            :rules="[
                                                (value) =>
                                                    validateTime(value) ||
                                                    'Please enter a valid time',
                                            ]"
                                        ></v-text-field>
                                        <VueDatePicker
                                            v-model="step.start_time"
                                            time-picker
                                            format="HH:mm"
                                            model-type="HH:mm"
                                            :position="
                                                !$vuetify.display.smAndDown
                                                    ? 'right'
                                                    : 'center'
                                            "
                                        >
                                            <template #trigger>
                                                <StandardBtn
                                                    type="custom"
                                                    variant="plain"
                                                    custom-color="black"
                                                    custom-icon="mdi-clock-outline"
                                                />
                                            </template>
                                        </VueDatePicker>
                                        <div class="mx-2">-</div>
                                        <v-text-field
                                            style="min-width: 75px"
                                            v-model="step.end_time"
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                            placeholder="hh:mm"
                                            :rules="[
                                                (value) =>
                                                    validateTime(value) ||
                                                    'Please enter a valid time',
                                            ]"
                                        ></v-text-field>
                                        <VueDatePicker
                                            v-model="step.end_time"
                                            time-picker
                                            format="HH:mm"
                                            model-type="HH:mm"
                                            :position="
                                                !$vuetify.display.smAndDown
                                                    ? 'right'
                                                    : 'center'
                                            "
                                        >
                                            <template #trigger>
                                                <StandardBtn
                                                    type="custom"
                                                    variant="plain"
                                                    custom-color="black"
                                                    custom-icon="mdi-clock-outline"
                                                />
                                            </template>
                                        </VueDatePicker>
                                        <!-- <v-text-field
                                            style="width: 75px"
                                            v-model="step.end_time"
                                            :active="step.menu.end_time"
                                            readonly
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                        >
                                            <v-menu
                                                v-model="step.menu.end_time"
                                                :close-on-content-click="false"
                                                activator="parent"
                                                transition="scale-transition"
                                            >
                                                <v-time-picker
                                                    v-if="step.menu.end_time"
                                                    v-model="step.end_time"
                                                    full-width
                                                    format="24hr"
                                                ></v-time-picker>

                                            </v-menu>
                                        </v-text-field> -->
                                    </div>
                                    <div
                                        v-if="
                                            isStartBiggerThanEnd(
                                                step.start_time,
                                                step.end_time,
                                            )
                                        "
                                    >
                                        <span class="text-caption text-danger"
                                            >Start time should be smaller</span
                                        >
                                    </div>
                                    <v-divider
                                        class="my-2"
                                        v-if="l < timeSlotDay.steps.length - 1"
                                    ></v-divider>
                                </div>
                                <div
                                    v-if="validateEachDayTime(timeSlotDay)"
                                    class="text-caption position-absolute top-0 left-0 text-white bg-danger p-2"
                                >
                                    <v-icon
                                        v-bind="props"
                                        size="small"
                                        color="white"
                                        >mdi-alert</v-icon
                                    >
                                    <span>Please enter a valid time</span>
                                </div>
                                <div
                                    v-else-if="checkOverlap(timeSlotDay)"
                                    class="text-caption position-absolute top-0 left-0 text-white bg-danger p-2"
                                >
                                    <v-icon
                                        v-bind="props"
                                        size="small"
                                        color="white"
                                        >mdi-alert</v-icon
                                    >
                                    <span>Time slots are overlapping</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="timeSlotError"
                    class="text-danger d-flex align-center gap-1"
                >
                    <v-icon color="danger">mdi-alert</v-icon>
                    {{ timeSlotError }}
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeTimeSlotModal" color="primary">
                    Cancel
                </v-btn>
                <v-btn
                    @click="applyTimeslot"
                    prepend-icon="mdi-check"
                    color="primary"
                    :disabled="!isValidTimeSlot"
                >
                    Apply Timeslot
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import { ref, watch, onMounted, computed, nextTick } from "vue";
import {
    fetchTimeZones,
    fetchFilterData2,
    validateTimeSlot,
    createDistribution,
    updateDistribution,
    //getPublishersForFilter,
} from "./service";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
export default {
    name: "CreateDistributionModal",
    components: {
        VueDatePicker,
        draggable,
    },
    props: {
        pageNumber: {
            type: Number,
            default: 1,
        },
        distributionId: {
            type: String,
            default: () => null,
        },
        distributionData: {
            type: Object,
            default: () => null,
        },
    },
    setup(props, ctx) {
        const store = useStore();
        // start of page 1
        const page = ref(1);
        const dialog = ref(true); // should always be true
        const filterData = ref(null);
        const createDistributionError = ref(null);
        const label = ref("");
        const tags = ref([]);
        const method = ref("slot");
        const timeZones = ref([]);
        const timeZone = ref(null);
        const traffic = ref(false);
        const countries = computed(() => store.getters.getCountries);
        const country = ref("");
        const publishers = ref([]);
        const selectedPublisher = ref(null);
        const parameterLabels = ref([]);

        const visibleParameterLabels = computed(()=>{
            let temp =[...parameterLabels.value]
            if(selectedParameters.value){
                selectedParameters.value.forEach(selectedParam =>{
                    temp = temp.filter(
                        (value) => value.id !== selectedParam.label.id,
                    );
                })
            }
            return temp

        })
        // const parameterValues = ref([]);
        const parameterValues = computed(() => {
            if (filterData.value && parameterLabel.value?.name) {
                return filterData.value[parameterLabel.value["name"]]["data"];
            } else return [];
        });
        const parameterLabel = ref("");
        const parameterValue = ref("");
        const selectedParameters = ref([]);
        const overwriteLabels = ref([]);
        const visibleOverwriteLabels = computed(()=>{
            let temp = [...overwriteLabels.value]
            if(selectedOverwrites.value){
                selectedOverwrites.value.forEach(selectedOverwrite =>{
                    temp = temp.filter(
                        (value) => value.id !== selectedOverwrite.label.id,
                    );
                })
            }
            return temp

        })
        const overwriteValues = ref([]);
        const overwriteLabel = ref("");
        const overwriteValue = ref([]);
        const selectedOverwrites = ref([]);

        const showParameterForm = ref(false);
        const showOverwriteForm= ref(false);

        const isLoadingTimeZones = ref(false);
        const isLoadingPublishers = ref(false);

        function addParameter() {



            selectedParameters.value.push({
                label: parameterLabel.value,
                value: parameterValue.value,
            });

            parameterLabel.value = "";
            parameterValue.value = "";
            showParameterForm.value = false;

        }
        function removeParameter(index) {
            parameterLabels.value.push(selectedParameters.value[index].label);
            selectedParameters.value.splice(index, 1);
        }
        function addOverwrite() {

            selectedOverwrites.value.push({
                label: overwriteLabel.value,
                value: overwriteValue.value,
            });
            overwriteLabel.value = "";
            overwriteValue.value = [];
            showOverwriteForm.value = false;
        }
        function removeOverwrite(index) {
            overwriteLabels.value.push(selectedOverwrites.value[index].label);
            selectedOverwrites.value.splice(index, 1);
        }
        function togglePage() {
            page.value = page.value !== 1 ? 1 : 2;
        }
        // end of page 1
        async function submit() {
            const step1Payload = {
                taggingSelected: tags.value,
                selectedCountry: {
                    id: country.value,
                },
                selectedTimeZone: {
                    id: timeZone.value,
                },
                labelValue: label.value,
                selectedMethod: method.value,
                isSendInOpHours: traffic.value,
                selectedAffiliate: {
                    id: selectedPublisher.value.id,
                },
                parameters: Object.fromEntries(
                    selectedParameters.value.map((parameter) => [
                        parameter.label.id,
                        parameter.value.id,
                    ]),
                ),
                data_override: Object.fromEntries(
                    selectedOverwrites.value.map((parameter) => [
                        parameter.label.id,
                        parameter.value,
                    ]),
                ),
            };
            const step2Payload = [];
            for (let i = 0; i < tabs.value.length; i++) {
                const tab = tabs.value[i];
                const timeSlot = timeSlots.value[i];
                step2Payload.push({
                    name: timeSlot["name"],
                    groups: tab.map((group) => {
                        return {
                            name: group.name,
                            brands: group.brands.map((brand) => {
                                console.log(brand);
                                return {
                                    name: brand.data?.name,
                                    percentage: brand.percentage,
                                    id: brand.data?.id,
                                };
                            }),
                        };
                    }),
                    isActive: 1,
                    openingHours: timeSlot["days"].map((day) => {
                        return {
                            id: day.id,
                            checked: day.checked,
                            steps: day.steps.map((step) => {
                                return {
                                    start_time: step.start_time,
                                    end_time: step.end_time,
                                };
                            }),
                        };
                    }),
                });
            }
            const payload = {
                step1: step1Payload,
                step2: step2Payload,
                from: "distribution",
            };
            try {
                if (props.distributionId) {
                    payload.distribution_id = props.distributionId;
                    await updateDistribution(payload);
                } else {
                    await createDistribution(payload);
                }

                ctx.emit("close");
                const message = `Distribution ${
                    props.distributionId ? "updated" : "created"
                } successfully`;
                Swal.fire({
                    icon: "success",
                    title: message,
                    text: message,
                }).then(() => {
                    // refresh page
                    window.location.reload();
                });
            } catch (error) {
                console.log(error.message);
                console.log(error.name);
                createDistributionError.value = error.message
                    ? error.message
                    : `Failed to ${
                          props.distributionId ? "update" : "create"
                      } distribution`;
            }
            // if (!data) {

            //     Swal.fire({
            //         icon: "error",
            //         title: createDistributionError.value,
            //         text: createDistributionError.value,
            //     });
            // } else {

            //     ctx.emit("success", {
            //         message: `Distribution ${
            //             props.distributionId ? "updated" : "created"
            //         } successfully`,
            //     });
            // }
        }
        // Start of page 2
        const tabs = ref([
            // tab
            [
                // Groups
                {
                    name: null,
                    brands: [],
                },
            ],
            // tab
            // [
            //     // Groups
            //     {
            //         name: null,
            //         brands: [
            //             {
            //                 data: {
            //                     id: 1,
            //                     name: "something",
            //                 },
            //                 percentage: 50,
            //             },
            //             {
            //                 data: {
            //                     id: 2,
            //                     name: "something",
            //                 },
            //                 percentage: 100,
            //             },
            //         ],
            //     },
            //     {
            //         name: null,
            //         brands: [],
            //     },
            // ],
        ]);
        const tab = ref(null);
        const brands = ref([]);
        watch(tabs, (newVal) => {
            tab.value = newVal;
        });
        function calculateGroupShare(group) {
            return group.brands.reduce(
                (acc, brand) => acc + Number(brand.percentage),
                0,
            );
        }
        function addTabToLeft(tabIndex) {
            tabs.value.splice(tabIndex, 0, [
                {
                    name: null,
                    brands: [],
                },
            ]);
            tab.value = `tab-${tabIndex}`;
            timeSlots.value.splice(
                tabIndex,
                0,
                JSON.parse(JSON.stringify(initialTimeSlot)),
            );
        }
        function addTabToRight(tabIndex) {
            tabs.value.splice(tabIndex + 1, 0, [
                {
                    name: null,
                    brands: [],
                },
            ]);
            tab.value = `tab-${tabIndex + 1}`;
            timeSlots.value.splice(
                tabIndex + 1,
                0,
                JSON.parse(JSON.stringify(initialTimeSlot)),
            );
        }
        function removeTab(tabIndex) {
            if (tabs.value.length > 1) {
                tabs.value.splice(tabIndex, 1);
                timeSlots.value.splice(tabIndex, 1);
                tab.value = `tab-${tabIndex - 1}`;
            }
        }
        function addGroup(tabIndex) {
            tabs.value[tabIndex].push({
                name: null,
                brands: [],
                id: crypto.randomUUID()
            });
        }
        function removeAllGroups(tabIndex) {
            tabs.value[tabIndex] = [];
        }
        function removeGroup(tabIndex, groupIndex) {
            tabs.value[tabIndex].splice(groupIndex, 1);
        }
        function moveGroupUp(tabIndex, groupIndex) {
            if (groupIndex > 0) {
                const temp = tabs.value[tabIndex][groupIndex];
                tabs.value[tabIndex][groupIndex] =
                    tabs.value[tabIndex][groupIndex - 1];
                tabs.value[tabIndex][groupIndex - 1] = temp;
            }
        }
        const hasInvalidPercentages = computed(() => {
            // First check if we have any tabs at all
            if (!tabs.value || tabs.value.length === 0) {
                return true;
            }

            // Check each tab group
            return tabs.value.some((tabGroups, tabIndex) => {
                // Check each group within the tab
                return tabGroups.some((group, groupIndex) => {
                    // Case 1: Empty brands array is invalid
                    //   if (!group.brands || group.brands.length === 0) {
                    //       console.log(
                    //           `Tab ${tabIndex}, Group ${groupIndex}: Empty brands array`,
                    //       );
                    //       return true;
                    //   }

                    // Case 2: Check if any brand has zero percentage
                    //  const hasZeroPercentage = group.brands.some(
                    //      (brand) =>
                    //          brand.percentage === 0 ||
                    //          brand.percentage === null ||
                    //          brand.percentage === undefined,
                    //  );

                    //   if (hasZeroPercentage) {
                    //       console.log(
                    //           `Tab ${tabIndex}, Group ${groupIndex}: Contains zero or null percentage`,
                    //       );
                    //       return true;
                    //   }

                    // Case 3: Calculate total percentage for this group's brands
                    const totalPercentage = group.brands.reduce(
                        (sum, brand) => {
                            return sum + (Number(brand.percentage) || 0);
                        },
                        0,
                    );

                    // Check if total is not exactly 100
                    const isInvalidTotal =
                        Math.abs(totalPercentage - 100) > 0.01; // Using small epsilon for floating point comparison

                    if (isInvalidTotal) {
                        console.log(
                            `Tab ${tabIndex}, Group ${groupIndex}: Invalid total percentage ${totalPercentage}%`,
                        );
                        return true;
                    }

                    return false;
                });
            });
        });
        function moveGroupDown(tabIndex, groupIndex) {
            if (groupIndex < tabs.value[tabIndex].length - 1) {
                const temp = tabs.value[tabIndex][groupIndex];
                tabs.value[tabIndex][groupIndex] =
                    tabs.value[tabIndex][groupIndex + 1];
                tabs.value[tabIndex][groupIndex + 1] = temp;
            }
        }
        function addBrand(tabIndex, groupIndex) {
            // Get current brands array
            const brands = tabs.value[tabIndex][groupIndex].brands;
            // Calculate total existing percentage
            const totalPercentage = brands.reduce(
                (sum, brand) => sum + brand.percentage,
                0,
            );
            // Determine remaining percentage available
            const remainingPercentage = Math.max(0, 100 - totalPercentage);
            // Only add new brand if there's room for it
            if (remainingPercentage > 0) {
                brands.push({
                    data: null,
                    percentage: remainingPercentage,
                });
            } else {
                brands.push({
                    data: null,
                    percentage: 0,
                });
            }
        }
        function moveBrandUp(tabIndex, groupIndex, brandIndex) {
            if (brandIndex > 0) {
                const temp =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex];
                tabs.value[tabIndex][groupIndex].brands[brandIndex] =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex - 1];
                tabs.value[tabIndex][groupIndex].brands[brandIndex - 1] = temp;
            }
        }
        function moveBrandDown(tabIndex, groupIndex, brandIndex) {
            if (
                brandIndex <
                tabs.value[tabIndex][groupIndex].brands.length - 1
            ) {
                const temp =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex];
                tabs.value[tabIndex][groupIndex].brands[brandIndex] =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex + 1];
                tabs.value[tabIndex][groupIndex].brands[brandIndex + 1] = temp;
            }
        }
        function removeBrand(tabIndex, groupIndex, brandIndex) {
            tabs.value[tabIndex][groupIndex].brands.splice(brandIndex, 1);
        }
        // end of page 2
        // Start of Timeslot Modal
        const timeSlotDialog = ref(false);
        const timeSlotIndex = ref(0);
        const timeSlotsPreviousState = ref(null);
        const timeSlotError = ref(null);
        const initialTimeSlot = {
            name: "Default",
            days: [
                {
                    id: 1,
                    checked: true,
                    label: "Monday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 2,
                    checked: true,
                    label: "Tuesday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 3,
                    checked: true,
                    label: "Wednesday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 4,
                    checked: true,
                    label: "Thursday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 5,
                    checked: true,
                    label: "Friday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 6,
                    checked: true,
                    label: "Saturday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 7,
                    checked: true,
                    label: "Sunday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
            ],
        };
        const timeSlots = ref([
            {
                name: "Default",
                days: [
                    {
                        id: 1,
                        checked: true,
                        label: "Monday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 2,
                        checked: true,
                        label: "Tuesday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 3,
                        checked: true,
                        label: "Wednesday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 4,
                        checked: true,
                        label: "Thursday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 5,
                        checked: true,
                        label: "Friday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 6,
                        checked: true,
                        label: "Saturday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 7,
                        checked: true,
                        label: "Sunday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                ],
            },
        ]);
        function checkOverlap(day) {
            // return timeSlot.some((day) => {
            return day.steps?.some((step, index) => {
                return day.steps?.some((nextStep, nextIndex) => {
                    if (index !== nextIndex) {
                        return isTimeOverlap(
                            step.start_time,
                            step.end_time,
                            nextStep.start_time,
                            nextStep.end_time,
                        );
                    }
                    return false;
                });
            });
            // });
        }
        function validateEachDayTime(day) {
            return day.steps?.some((step) => {
                return (
                    !validateTime(step.start_time) ||
                    !validateTime(step.end_time)
                );
            });
        }
        function timeToMinutes(time) {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes;
        }
        function isStartBiggerThanEnd(start, end) {
            return timeToMinutes(start) > timeToMinutes(end);
        }
        function isTimeOverlap(start1, end1, start2, end2) {
            const start1Time = timeToMinutes(start1);
            const end1Time = timeToMinutes(end1);
            const start2Time = timeToMinutes(start2);
            const end2Time = timeToMinutes(end2);
            return start1Time < end2Time && end1Time > start2Time;
        }
        const isValidTimeSlot = computed(() => {
            const selectedTimeSlot = timeSlots.value[timeSlotIndex.value];
            if (
                selectedTimeSlot.name === "" ||
                !selectedTimeSlot.name ||
                selectedTimeSlot.name.trim() === ""
            ) {
                return false;
            }
            for (let i = 0; i < selectedTimeSlot.days.length; i++) {
                const day = selectedTimeSlot.days[i];
                if (checkOverlap(day)) {
                    console.log("overlap");
                    return false;
                }
                for (let j = 0; j < day.steps.length; j++) {
                    const step = day.steps[j];
                    if (isStartBiggerThanEnd(step.start_time, step.end_time)) {
                        console.log("start bigger than end");
                        return false;
                    }
                    if (
                        !validateTime(step.start_time) ||
                        !validateTime(step.end_time)
                    ) {
                        return false;
                    }
                }
            }
            return true;
        });
        function openTimeSlotDialog(i) {
            console.log("index", i);
            timeSlotError.value = null;
            timeSlotsPreviousState.value = JSON.parse(
                JSON.stringify(timeSlots.value),
            );
            timeSlotIndex.value = i;
            timeSlotDialog.value = true;
        }
        function validateTime(time) {
            console.log("validateTime", time);
            if (!time) {
                return false;
            }
            const timeParts = time.split(":");
            if (timeParts.length !== 2) {
                return false;
            }
            const hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1]);
            if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
                return false;
            }
            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                return true;
            }
            return false;
        }
        function addStep(timeSlotDay) {
            timeSlotDay.steps.push({
                menu: {
                    start_time: false,
                    end_time: false,
                },
                start_time: "00:00",
                end_time: "23:59",
            });
        }
        function removeStep(timeSlotDay, index) {
            timeSlotDay.steps.splice(index, 1);
        }
        function copyStepsToAll(timeSlotDay, index) {
            timeSlots.value[index].days.forEach((day) => {
                day.steps = JSON.parse(
                    JSON.stringify(
                        timeSlotDay.steps.map((step) => {
                            return {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: step.start_time,
                                end_time: step.end_time,
                            };
                        }),
                    ),
                );
            });
        }
        function copyPreviousStep(timeSlotDay, timeSlotIndex, dayIndex) {
            const previousSteps =
                timeSlots.value[timeSlotIndex].days[dayIndex].steps;
            timeSlotDay.steps = JSON.parse(
                JSON.stringify(
                    previousSteps.map((step) => {
                        return {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: step.start_time,
                            end_time: step.end_time,
                        };
                    }),
                ),
            );
        }
        function closeTimeSlotModal() {
            timeSlotDialog.value = false;
            timeSlots.value = JSON.parse(
                JSON.stringify(timeSlotsPreviousState.value),
            );
        }
        async function applyTimeslot() {
            console.log("applyTimeslot");
            const payload = {
                timeslots: timeSlots.value.map((timeSlot) => {
                    return timeSlot.days.map((day) => {
                        return {
                            id: day.id,
                            name: day.label,
                            checked: day.checked,
                            steps: day.steps.map((step) => {
                                return {
                                    start_time: step.start_time,
                                    end_time: step.end_time,
                                };
                            }),
                        };
                    });
                }),
            };
            const data = await validateTimeSlot(payload);
            if (data?.error || !data) {
                console.log("Invalid timeslot");
                timeSlotError.value = "Time already taken";
            } else {
                timeSlotsPreviousState.value = null;
                timeSlotDialog.value = false;
            }
        }
        function dayFromId(id) {
            switch (id) {
                case 1:
                    return "Monday";
                case 2:
                    return "Tuesday";
                case 3:
                    return "Wednesday";
                case 4:
                    return "Thursday";
                case 5:
                    return "Friday";
                case 6:
                    return "Saturday";
                case 7:
                    return "Sunday";
            }
        }

        // End of Timeslot Modal
        /*function onPublisherFocus() {
            // Only load publishers if they haven't been loaded yet
            if (publishers.value.length === 0) {
                loadPublishers();
            }
        }*/
        /*const loadPublishers = async () => {
            isLoadingPublishers.value = true;
            try {
                const response = await getPublishersForFilter();
                if (response?.data) {
                    publishers.value = response.data;

                    // Only set selected publisher in edit mode
                    if (props.distributionData?.step1?.selectedAffiliate?.id) {
                        const publisherId = props.distributionData.step1.selectedAffiliate.id;
                        const publisher = response.data.find(p => p.id === publisherId);
                        if (publisher) {
                            selectedPublisher.value = publisher;
                        }
                    }
                }
            } catch (error) {
                console.error('Error loading publishers:', error);
                publishers.value = [];
            } finally {
                isLoadingPublishers.value = false;
            }
        };
        // Only load publishers immediately in edit mode
        if (props.distributionData) {
            loadPublishers();
        }*/
        onMounted(async () => {
            if (props.pageNumber == 2) {
                page.value = 2;
            }
            // fill first page





            if (props.distributionData) {
               // fill second page
                tabs.value = props.distributionData.step2.map((timeslot) => {
                    return timeslot.groups.map((group) => {
                        return {
                            name: group.name,
                            brands: group.brands.map((brand) => {
                                return {
                                    data: {
                                        id: brand.id,
                                        name: brand.name,
                                    },
                                    percentage: brand.percentage,
                                    id: crypto.randomUUID()
                                };
                            }),
                        };
                    });
                });
                timeSlots.value = props.distributionData.step2.map(
                    (timeslot) => {
                        return {
                            name: timeslot.name,
                            days: timeslot.openingHours.map((day) => {
                                return {
                                    id: day.id,
                                    checked: day.checked,
                                    label: dayFromId(day.id),
                                    steps: day.steps?.map((step) => {
                                        return {
                                            menu: {
                                                start_time: false,
                                                end_time: false,
                                            },
                                            start_time: step.start_time
                                                .split(":")
                                                .slice(0, 2)
                                                .join(":"),
                                            end_time: step.end_time
                                                .split(":")
                                                .slice(0, 2)
                                                .join(":"),
                                        };
                                    }),
                                };
                            }),
                        };
                    },
                );
            }
            filterData.value = await fetchFilterData2();
            try {
                isLoadingTimeZones.value = true;
                timeZones.value = await fetchTimeZones();
                if (!props.distributionData) {  // Only set default if not editing existing distribution
                    timeZone.value = 241;  // Set default after time zones are loaded
                }
            } finally {
                isLoadingTimeZones.value = false;
            }
            for (const parameter in filterData.value) {
                parameterLabels.value.push({
                    name: parameter,
                    id: filterData.value[parameter].id,
                });
                overwriteLabels.value.push({
                    name: parameter,
                    id: filterData.value[parameter].id,
                });
                // parameterValues.value = [
                //     ...parameterValues.value,
                //     ...filterData.value[parameter].data,
                // ];
            }
            if (props.distributionData) {
                label.value = props.distributionData.step1?.labelValue;
                tags.value = props.distributionData.step1?.taggingSelected;
                method.value = props.distributionData.step1?.selectedMethod;
                timeZone.value =
                    props.distributionData.step1?.selectedTimeZone?.id;
                traffic.value =
                    props.distributionData.step1?.isSendInOpHours == 1;
                country.value =
                    props.distributionData.step1?.selectedCountry?.id;
                selectedPublisher.value =
                    props.distributionData.step1?.selectedAffiliate?.id;
                nextTick(()=>{
                    if(props.distributionData?.step1?.parameters){
                        for (const [tempParameterLabelId, tempParameterValueId] of Object.entries(props.distributionData.step1.parameters)) {

                            const tempParameterLabel = parameterLabels.value.find(
                                (value) => value.id === tempParameterLabelId,
                            );

                            const tempParameterValue= filterData.value[tempParameterLabel["name"]]["data"].find(
                                (value) => value.id === tempParameterValueId,
                            );

                            selectedParameters.value.push({
                                label: tempParameterLabel,
                                value: tempParameterValue,
                            });
                        }
                    }
                    if(props.distributionData?.step1?.data_override){
                        for (const [tempParameterLabelId1, tempParameterValue1] of Object.entries(props.distributionData.step1.data_override)) {

                            const tempParameterLabel1 = overwriteLabels.value.find(
                                (value) => value.id === tempParameterLabelId1,
                            );

                            selectedOverwrites.value.push({
                                label: tempParameterLabel1,
                                value: tempParameterValue1,
                            });
                        }
                    }

                })

            }


            brands.value = filterData.value["Advertisers"]["data"];
            publishers.value = filterData.value["Publishers"]["data"];
            tabs.value = tabs.value.map(tabGroups => {
                return tabGroups.map(group => ({
                    ...group,
                    id: crypto.randomUUID(),
                    brands: group.brands.map(brand => ({
                        ...brand,
                        id: crypto.randomUUID()
                    }))
                }));
            });
        });
        // Add this method to handle brand moves between groups
        const handleBrandMove = (evt, tabIndex, groupIndex) => {
            if (evt.added) {
                // Set the newly added brand's percentage to 0
                const newBrand = tabs.value[tabIndex][groupIndex].brands[evt.added.newIndex];
                if (newBrand) {
                    newBrand.percentage = 0;
                }
            }
        };
        return {
            // Start of Timeslot Modal
            timeSlotDialog,
            openTimeSlotDialog,
            timeSlots,
            timeSlotsPreviousState,
            timeSlotError,
            timeSlotIndex,
            addStep,
            removeStep,
            copyStepsToAll,
            copyPreviousStep,
            closeTimeSlotModal,
            applyTimeslot,
            checkOverlap,
            isStartBiggerThanEnd,
            isValidTimeSlot,
            // End of Timeslot Modal
            dialog,
            label,
            tags,
            method,
            timeZone,
            timeZones,
            traffic,
            country,
            countries,
            publishers,
            selectedPublisher,
            parameterLabels,
            parameterValues,
            parameterLabel,
            parameterValue,
            selectedParameters,
            overwriteLabels,
            overwriteLabel,
            overwriteValue,
            overwriteValues,
            selectedOverwrites,
            addParameter,
            removeParameter,
            addOverwrite,
            removeOverwrite,
            togglePage,
            page,
            // addTab,
            addTabToLeft,
            addTabToRight,
            tabs,
            tab,
            removeTab,
            addGroup,
            removeAllGroups,
            removeGroup,
            moveGroupUp,
            moveGroupDown,
            addBrand,
            moveBrandUp,
            moveBrandDown,
            removeBrand,
            calculateGroupShare,
            brands,
            submit,
            createDistributionError,
            hasInvalidPercentages,
            validateTime,
            validateEachDayTime,

            showOverwriteForm,
            showParameterForm,

            visibleParameterLabels,
            visibleOverwriteLabels,
            isLoadingTimeZones,
            handleBrandMove,
            isLoadingPublishers,
            //onPublisherFocus
        };
    },
};
</script>
<style scoped>
.drag-handle {
    cursor: move;
}

/* Add hover effect to make it more obvious it's draggable */
.drag-handle:hover {
    background-color: rgba(var(--v-theme-on-surface), 0.04);
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
}

.empty-group-placeholder {
    border: 2px dashed rgba(var(--v-theme-on-surface), 0.2);
    border-radius: 4px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.min-height-60 {
    min-height: 60px;
}

.brand-container {
    position: relative;
    min-height: 60px;
}

.empty-group-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed rgba(var(--v-theme-on-surface), 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 0;
}

.dragArea {
    min-height: 60px;
    position: relative;
    z-index: 1;
}

.v-tab,
.v-tabs,
.v-window__container,
.v-window-item,
.v-tab-slider,
.v-window,
.v-tabs-slider,
.v-tabs-bar,
.v-tabs__container,
.v-tabs__div,
.v-tabs__wrapper,
.v-tabs-items {
    transition: none !important;
}

.v-window-item {
    position: relative !important;
    visibility: visible !important;
    display: block !important;
}

.disable-transitions * {
    transition: none !important;
}
</style>

<template>
    <div class="row mt-4">
        <div
            class="col-12 col-md-4 col-lg-3 mb-1"
            v-for="keyName in Object.keys(cardItems)"
            :key="keyName">
            <div class="card border-primary" v-if="checkPermission(keyName)">
                <div class="card-body text-center text-white bg-primary">
                    <p class="card-text mb-0">
                        {{ keyName }} <br />
                        <span v-if="!isLoading">
                            {{ cardItems[keyName] }}
                        </span>
                        <v-skeleton-loader
                            type="text"
                            color="primary"
                            v-else
                        ></v-skeleton-loader>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchLeadMetrics } from "./service";
import ReportPermission from "./modules/report.permission";

export default {
    name: "GlobalDrillDown",
    props: {
        filters: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        isLoading() {
            return this.innerLoading || this.loading;
        },
    },
    data() {
        return {
            innerLoading: false,
            cardItems: {
                Attempts: 0,
                Leads: 0,
                Success: "0%",
                Autologged: 0,
                "Autologged Ratio": 0,
                "Total FTDs": 0,
                "Publisher FTDs": 0,
                "Late FTDs": 0,
                Conversion: 0.0,
                "Aff Conversion": 0.0,
                eCPL: 0.0,
                AeCPL: 0.0,
                Revenue: 0,
                Payout: 0,
                Profit: 0,
            },
            report_permission: new ReportPermission()
        };
    },
    watch: {
        async filters(newVal) {
            await this.fetchData(newVal);
        },
    },
    methods: {
        async fetchData(value) {
            this.innerLoading = true;
            const receivedFilters = value || this.filters;
            const payload = {
                ...receivedFilters,
                publisher_status: receivedFilters.brand_status,
            };
            delete payload.brand_status;
            const data = await fetchLeadMetrics(payload);
            if (data) {
                this.cardItems.Attempts = data?.attempts;
                this.cardItems.Leads = data?.leads;
                this.cardItems.Success = data?.success_percent;
                this.cardItems.Autologged = data?.autologged;
                this.cardItems["Autologged Ratio"] = data?.autologged_percent;
                this.cardItems["Total FTDs"] = data?.total_ftd;
                this.cardItems["Publisher FTDs"] = data?.publisher_ftd;
                this.cardItems["Late FTDs"] = data?.late_ftd;
                this.cardItems.Conversion = data?.conversion;
                this.cardItems["Aff Conversion"] = data?.publisher_conversion;
                this.cardItems.eCPL = data?.ecpl;
                this.cardItems.AeCPL = data?.aecpl;
                this.cardItems.Revenue = data?.revenue;
                this.cardItems.Payout = data?.payout;
                this.cardItems.Profit = data?.profit;
            } else {
                this.cardItems = {
                    Attempts: 0,
                    Leads: 0,
                    Success: "0%",
                    Autologged: "X",
                    "Autologged Ratio": 0,
                    "Total FTDs": 0,
                    "Publisher FTDs": 0,
                    "Late FTDs": 0,
                    Conversion: 0.0,
                    "Aff Conversion": 0.0,
                    eCPL: 0.0,
                    AeCPL: 0.0,
                    Revenue: 0,
                    Payout: 0,
                    Profit: 0,
                };
            }
            this.innerLoading = false;
        },
        checkPermission(module) {
            return this.report_permission.hasPermissionToShow("global", module, this.$store.getters.getUserPermisssion)
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>

<style scoped>
/* Optional: Additional custom styles can be added here */
.card {
    border-radius: 0.375rem; /* Use Bootstrap's default border radius */
}
.card .card-body,
.card-light .card-body {
    /* padding: 1.25rem; */
    height: 100px;
    align-items: center;
    /* display: flex; */
    align-content: center;
}
</style>

<template>
    <v-app light>
        <v-app-bar color="bar" prominent>
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
                aria-label="Button for Navbar Menu"
            >
                <v-icon aria-label="menu bar icon">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <ThemeBtn /> -->
            <template v-slot:append>
                <v-autocomplete
                    v-if="$vuetify.display.mdAndUp"
                    v-model="timezone"
                    :items="timezones"
                    item-title="name"
                    item-value="id"
                    density="compact"
                    variant="outlined"
                    hide-details
                    width="250"
                ></v-autocomplete>
                &nbsp; &nbsp;
                <v-btn icon @click="logout" v-if="$vuetify.display.mdAndUp">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
                <v-menu
                    v-if="$vuetify.display.smAndDown"
                    transition="slide-y-transition"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn icon v-bind="props">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <template v-slot:default>
                                <v-select
                                    v-model="timezone"
                                    :items="timezones"
                                    item-title="name"
                                    item-value="id"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    width="250"
                                ></v-select>
                            </template>
                            <template v-slot:append>
                                <v-btn icon flat @click="logout">
                                    <v-icon>mdi-logout</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <v-navigation-drawer
            color="bar"
            v-model="drawer"
            :permanent="$vuetify.display.mdAndUp"
            :temporary="$vuetify.display.mobile"
            :location="$vuetify.display.mobile ? 'left' : undefined"
            :rail="!$vuetify.display.smAndDown && rail"
            @click="rail = false"
            width="270"
        >
            <v-list color="white" theme="dark">
                <template v-for="(item, i) in items">
                    <v-list-item
                        :key="i"
                        v-if="hasPermission(item.permission)"
                        :to="item.to"
                        router
                        :active="item.activeOnPages.includes($route.name)"
                    >
                        <template v-slot:prepend>
                            <i :class="item.icon"></i>
                        </template>
                        <template v-slot:title>
                            <div class="d-flex align-center">
                                <span class="ml-3">
                                    {{ item.title }}
                                </span>
                                &nbsp;
                                <span
                                    class="badge"
                                    style="background-color: #7e3584"
                                    v-if="item.title === 'Pending Conversions'"
                                    >{{ pendingFtd }}</span
                                >
                            </div>
                        </template>
                    </v-list-item>
                </template>
                <v-list-item v-if="!rail && $vuetify.display.mdAndUp">
                    <template v-slot:append>
                        <v-btn
                            icon="mdi-chevron-left"
                            variant="text"
                            @click.stop="rail = !rail"
                        ></v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid id="main-container">
                <!-- showNewFtdAnimation -->
                <div v-if="showNewFtdAnimation" id="confetti-container1" v-confetti="{ particleCount: 200, force: 0.3,duration: 4500, stageHeight: $vuetify.display.height ,stageWidth: $vuetify.display.width*0.85 }" />
                <div v-if="showNewFtdAnimation" id="confetti-container2" v-confetti="{ particleCount: 200, force: 0.3,duration: 4500, stageHeight: $vuetify.display.height  ,stageWidth: $vuetify.display.width*0.85  }" />

                <slot></slot>

                <v-snackbar
                    v-model="showFtdSnakeBar"
                    color="indigo-darken-2"
                    :timeout="10000"
                    >
                    <span class="fw-bold"> New Conversion!</span>

                    <template v-slot:actions>
                        <v-btn
                        color="white"
                        variant="text"
                        @click="showFtdSnakeBar = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-container>
        </v-main>

        <!-- <v-footer fixed app>
                <span
                    >&copy; {{ new Date().getFullYear() }} All rights
                    reserved.</span
                >
            </v-footer> -->
    </v-app>
</template>
<script>
import { vConfetti } from '@neoconfetti/vue';

import { computed, ref, watch,onMounted  } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import Swal from "sweetalert2";
import { usePermissions } from "@/composables/usePermissions.js";
import { useStore } from "vuex";

export default {
    name: "DefaultLayout",
    directives: {
        confetti: vConfetti, // Register the confetti directive
    },
    setup() {
        const store = useStore();
        const user = computed(()=>store.getters.getUser)

        const userSettings= computed(() => store.getters.getUserSettings);

        const lastPendiFtdDate= computed(() => store.getters.getLastPendiFtdDate);
        const allowNewFtdNotification= computed(() => store.getters.getUserSettings?.extras?.allow_new_ftd_notification);
        //new
        const allowWatchFendingFtd = ref(false)

        const showNewFtdAnimation= ref(false);
        const showFtdSnakeBar=ref(false)
        const rail = ref(false);
        const miniVariant = ref(true);
        const drawer = ref(true);
        const items = ref([
                {
                    icon: "fas fa-home",
                    title: "Dashboard",
                    to: { name: "DashboardView" },
                    activeOnPages: ["DashboardView"],
                    permission: "DASHBOARDS_LIST",
                },
                {
                    icon: "fas fa-file-alt",
                    title: "Reports",
                    to: "/reports",
                    activeOnPages: ["ReportsView"],
                    permission: "REPORTS_LIST",
                },
                {
                    icon: "fas fa-mouse-pointer",
                    title: "Click Reports",
                    to: { name: "ClickReportsView" },
                    activeOnPages: ["ClickReportsView"],
                    permission: "CLICK_REPORTS_LIST",
                },

                {
                    icon: "fas fa-user-friends",
                    title: "Leads",
                    to: { name: "LeadsView" },
                    activeOnPages: ["LeadsView"],
                    permission: "LEADS_LIST",
                },
                {
                    icon: "fas fa-exclamation-circle",
                    title: "Pending Conversions",
                    to: { name: "PendingConversionView" },
                    activeOnPages: ["PendingConversionView"],
                    permission: "PENDING_CONVERSIONS_LIST",
                },
                {
                    icon: "fas fa-user-tie",
                    title: "Advertisers",
                    to: { name: "AdvertisersView" },
                    activeOnPages: [
                        "AdvertisersView",
                        "AdvertiserEditView",
                        "CreateAdvertiserView",
                    ],
                    permission: "ADVERTISERS_LIST",
                },
                {
                    icon: "fas fa-share-alt",
                    title: "Distributions",
                    to: { name: "DistributionsView" },
                    activeOnPages: ["DistributionsView"],
                    permission: "DISTRIBUTIONS_LIST",
                },
                {
                    icon: "fas fa-users",
                    title: "Publishers",
                    to: { name: "PublishersView" },
                    activeOnPages: [
                        "PublishersView",
                        "CreatePublisherView",
                        "PublisherEditView",
                    ],
                    permission: "PUBLISHERS_LIST",
                },
                {
                    icon: "fas fa-box-open",
                    title: "Offers",
                    to: { name: "OffersView" },
                    activeOnPages: ["OffersView"],
                    permission: "OFFERS_LIST",
                },
                {
                    icon: "fas fa-bullhorn",
                    title: "Campaigns",
                    to: { name: "CampaignsView" },
                    activeOnPages: ["CampaignsView"],
                    permission: "CAMPAIGNS_LIST",
                },
                {
                    icon: "fas fa-users",
                    title: "Users",
                    to: { name: "UsersView" },
                    activeOnPages: ["UsersView"],
                    permission: "USERS_LIST",
                },
                {
                    icon: "fas fa-users-cog",
                    title: "Roles",
                    to: { name: "RolesView" },
                    activeOnPages: ["RolesView"],
                    permission: "ROLES_LIST",
                },
                {
                    icon: "fas fa-cogs",
                    title: "Settings",
                    to: { name: "SettingsView" },
                    activeOnPages: ["SettingsView"],
                    permission: "SETTINGS_LIST",
                },
                {
                    icon: "fas fa-lock",
                    title: "Blacklist",
                    to: { name: "BlacklistView" },
                    activeOnPages: ["BlacklistView"],
                    permission: "BLOCKLIST_LIST",
                },
                {
                    icon: "fas fa-sliders-h",
                    title: "Caps",
                    to: { name: "CapsView" },
                    activeOnPages: ["CapsView"],
                    permission: "CAPS_LIST",
                },
                {
                    icon: "fas fa-tasks",
                    title: "Status Group",
                    to: { name: "leadStatusGroupView" },
                    activeOnPages: ["leadStatusGroupView"],
                    permission: "STATUS_GROUPS_LIST",
                },
        ])
        // eslint-disable-next-line


        const { hasPermission } = usePermissions();
        // const pendingFtd = ref(0);
        const pendingFtd =  computed(() => store.getters.getPendingFtdCount);

        const router = useRouter();
        const title = computed(() => process.env.VUE_APP_NAME);
        const timezones = computed(() => store.getters.getTimeZones);
        const timezone = ref(null);
        timezone.value = store.getters.getUser.timezone;
        watch(timezone, async (newVal) => {
            try {
                await axios.put("/api/users/timezone", {
                    timezone_id: newVal,
                    user_id: store.getters.getUser.user_id,
                });
                await store.dispatch("setUser", {
                    ...store.getters.getUser,
                    timezone: newVal,
                });
                const timezoneObj = timezones.value.find(
                    (tz) => tz.id == newVal,
                );
                Swal.fire({
                    icon: "success",
                    title: "Timezone changed",
                    text: `You just changed the time zone to (UTC${timezoneObj.offset}) ${timezoneObj.name}. All dates and times are adjusted to this time zone. If you change anything regarding any schedule, this applies to your chosen time zone.`,
                }).then(() => {
                    window.location.reload();
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to change timezone. Please try again.",
                }).then(() => {
                    window.location.reload();
                });
                console.log(error);
            }
        });

        watch(pendingFtd,  async (newVal, oldValue) => {
            if(userSettings.value){

                if(allowWatchFendingFtd.value && allowNewFtdNotification.value && newVal > oldValue){
                    showFtdSnakeBar.value = true
                    showNewFtdAnimation.value = true
                    await updateLastPendiFtdDate(formatUTCDate(new Date()))
                }

            }

        },{immediate:true})






        async function logout() {
            localStorage.removeItem("user_data");
            store.commit("setUser", null);
            router.push("/login");
        }

        async function fetchPendingFtd() {
            try {
                const res = await axios.get(
                    "/api/pending-conversions/pending-ftd",
                );
                store.commit('setPendingFtdCount',res.data.count)
            } catch (error) {
                console.log(error);
            }
        }

        async function fetchLastPendingFtd() {
            if(lastPendiFtdDate.value){
                const result = await axios.get("/api/pending-conversions/pending-ftd",
                    { params: { date: lastPendiFtdDate.value } }
                );

                if(result?.data?.count &&  allowNewFtdNotification.value){
                    showFtdSnakeBar.value = true
                    showNewFtdAnimation.value = true
                    await updateLastPendiFtdDate(formatUTCDate(new Date()))
                }
            }else{
                await updateLastPendiFtdDate(formatUTCDate(new Date()))
            }
            allowWatchFendingFtd.value = true
        }

        async function updateLastPendiFtdDate(date){
            store.commit("setLastPendiFtdDate", date);
            try {
                await axios.put("/api/user-settings",{
                    user_id: user.value.user_id,
                    extras: {
                        last_pending_ftd_date:date,
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }


        function formatUTCDate(input) {
            const date = new Date(input);
            if (isNaN(date.getTime())) {
                throw new Error("Invalid date format");
            }

            const pad = (num) => num.toString().padStart(2, '0');

            const year = date.getUTCFullYear();
            const month = pad(date.getUTCMonth() + 1);
            const day = pad(date.getUTCDate());
            const hours = pad(date.getUTCHours());
            const minutes = pad(date.getUTCMinutes());
            const seconds = pad(date.getUTCSeconds());

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }


        onMounted(async () => {
        // console.log(process.env.VUE_APP_APP_URL, "App URL");
        // console.log(process.env.VUE_APP_API_URL, "API URL");
        // console.log(process.env.VUE_APP_NAME, "App Name");
            await store.dispatch("fetchCountries");
            await store.dispatch("fetchTimeZones");
            await store.dispatch('fetchUserSettings')


            if (hasPermission("PENDING_CONVERSIONS_LIST")) {
                await fetchLastPendingFtd()
                await fetchPendingFtd();
                setInterval(async () => {
                    await fetchPendingFtd();
                }, 60000);
            }

        });

        return {
            title,
            logout,
            timezones,
            timezone,
            pendingFtd,
            hasPermission,

            rail,
            miniVariant,
            drawer,
            items,
            showNewFtdAnimation,
            showFtdSnakeBar,
        };
    },
};


// get start datetime from user settings in utc
// if not start dateTime:
//      fetch all pending ftds and
//      if it's > 0
// fetch pending ftds from starting date utc
// if count > 0 : notify , change start date to now in utc







</script>
<style scoped>
a:hover {
    color: #fff !important;
}
.v-main {
    max-width: 100vw;
}
</style>

<style>
/* create a global style for the sticky header capitalize for all tables */
thead.bh-sticky {
    text-transform: capitalize;
}
#confetti-container1{
    background: red;
    height: 250px;
    position:fixed;
    top: 0;
    left: 20%;
    pointer-events: none !important;

}
#confetti-container2{
    background: red;
    height: 250px;
    position:fixed;
    top: 0%;
    right: calc(20% + 1rem);
    transform: translateX(250);
    pointer-events: none !important;

}


.v-overlay:has(.v-snackbar__underlay){
    --v-layout-left: 0 !important;
}
.v-snackbar__wrapper{
    min-width: unset !important;
}
.v-snackbar__content{
    padding-right: 40px !important;
}
</style>

<template>
    <div class="card mt-2 mb-2 border-none" :style="`box-shadow: none; ${isRoot && 'min-height:calc(100vh - 310px);'}`">
        <v-data-table
            :cell-props="cellProps"
            :loading="isLoading"
            :headers="hideColumnsBasedOnPermission(columns)"
            :items="rows"
            v-model:expanded="expanded"
            class="custom-table border"
            item-value="id"
            density="compact"
            hide-default-footer
            :items-per-page="-1"
            :fixed-header="true"
            :fixed-footer="true"
            :sticky="true"
            :style="isRoot && 'max-height:calc(100vh - 260px)'"
            @update:sortBy="handleSortBy"
            disable-sort
        >
            <template #[`header.${updatedColumn.key}`] v-if="updatedColumn.key">
                <div class="d-flex align-items-center gap-2">
                    <strong class="text-bold">{{ updatedColumn.title }}</strong>
                    <div>
                        <v-icon>
                            {{
                                updatedColumn.order === "asc"
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                            }}
                        </v-icon>
                    </div>
                </div>
            </template>
            <template #[`item.advertiser_id`]="slotData">
                <div
                    v-if="slotData.item.advertiser_id !== 'Total'"
                    @click="openDefaultDrillDown(slotData)"
                    class="cursor-pointer text-decoration-underline"
                >
                    {{ slotData.item.advertiser_id }}
                </div>
                <div v-else>Total</div>
            </template>
            <template #[`item.affiliate_id`]="slotData">
                <div
                    v-if="slotData.item.affiliate_id !== 'Total'"
                    @click="openDefaultDrillDown(slotData)"
                    class="cursor-pointer text-decoration-underline"
                >
                    {{ slotData.item.affiliate_id }}
                </div>
                <div v-else>Total</div>
            </template>
            <template #[`item.advertisers`]="slotData">
                <!-- :to="{
                        name: 'AdvertiserEditView',
                        params: { id: slotData.item.advertiser_id },
                    }" -->
                <v-btn
                    v-if="slotData.item.advertiser_id !== 'Total'"
                    variant="text"
                    color="primary"
                    class="text-decoration-underline pa-0"
                    style="text-transform:none;"
                    @click.stop="
                        openLinkInNewTab({
                            name: 'AdvertiserEditView',
                            params: { id: slotData.item.advertiser_id },
                        })
                    "
                >
                    {{ slotData.item.advertisers }}
                    <!-- ({{ item.affiliate_id }}) -->
                </v-btn>
                <div v-else></div>
            </template>
            <!-- @click="openDefaultDrillDown(slotData)" -->
            <!-- :to="{
                name: 'PublisherEditView',
                params: { id: slotData.item.affiliate_id },
                _blank
            }" -->
            <template #[`item.affiliates`]="slotData">
                <v-btn
                    v-if="slotData.item.affiliate_id !== 'Total'"
                    variant="text"
                    color="primary"
                    class="text-decoration-underline pa-0"
                    style="text-transform:none;"
                    @click.stop="
                        openLinkInNewTab({
                            name: 'PublisherEditView',
                            params: { id: slotData.item.affiliate_id },
                        })
                    "
                >
                    {{ slotData.item.affiliates }}
                    <!-- ({{ item.affiliate_id }}) -->
                </v-btn>
                <div v-else></div>
            </template>
            <!-- <template #[`item.countries`]="slotData">
                <div @click="openDefaultDrillDown(slotData)">
                    {{ slotData.item.countries }}
                </div>
            </template> -->
            <template #[`item.offer`]="slotData">
                <div
                    v-if="slotData.item.offer !== 'Total'"
                    @click="openDefaultDrillDown(slotData)"
                    class="cursor-pointer text-decoration-underline"
                >
                    {{ slotData.item.offer }}
                </div>
                <div v-else>Total</div>
            </template>
            <template #[`item.created_at`]="slotData">
                <div
                    v-if="slotData.item.created_at !== 'Total'"
                    @click="openDefaultDrillDown(slotData)"
                    class="cursor-pointer text-decoration-underline"
                >
                    {{ slotData.item.created_at }}
                </div>
                <div v-else>Total</div>
            </template>
            <template #[`item.brand_status`]="slotData">
                <div
                    v-if="slotData.item.brand_status !== 'Total'"
                    @click="openDefaultDrillDown(slotData)"
                    class="cursor-pointer text-decoration-underline"
                >
                    {{ slotData.item.brand_status }}
                </div>
                <div v-else>Total</div>
            </template>
            <template #[`item.action`]="slotData">
                <button
                    v-if="!slotData.item?.notAction"
                    @click="
                        expanded.includes(slotData.item?.id)
                            ? actionColumn(slotData)
                            : openDialog(slotData)
                    "
                >
                    <i
                        :class="
                            expanded.includes(slotData.item?.id)
                                ? 'fa fa-angle-down'
                                : 'fa fa-angle-right'
                        "
                        style="color: #000"
                    ></i>
                </button>
            </template>
            <template #[`item.countries`]="{ item }">
                <span v-if="item.countries">
                    <!-- <CountryImg :country_name="item.countries" /> -->
                    <!-- <span class="ml-3">{{ item.countries }}</span> -->
                    <CountryInfo :name="item.countries" />
                </span>
            </template>
            <template #[`item.revenue`]="{ item }" >
                ${{ item.revenue }}
            </template>
            <template #[`item.payout`]="{ item }">
                ${{ item.payout }}
            </template>
            <template #[`item.profit`]="{ item }">
                ${{ item.profit }}
            </template>
            <template #[`item.margin`]="{ item }">
                ${{ item.margin }}
            </template>
            <template #[`item.cr`]="{ item }"> {{ item.cr }}% </template>
            <template #[`item.aff_cr`]="{ item }">
                {{ item.aff_cr }}%
            </template>

            <template v-slot:expanded-row="{ item }">
                <tr>
                    <td colspan="100%" class="nested-table-wrapper">
                        <NestedTable
                            :isRoot="false"
                            v-if="item.showNestedTable"
                            :columns="hideColumnsBasedOnPermission(item.nestedColumns)"
                            :rows="item.nestedRows"
                            :drill_down_list="item.drillDownList"
                            :filters="item.nestedFilters"
                            :tab="tab"
                            :key="item.id+'---'+(Math.random() + 1).toString(36).substring(7)"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" width="auto">
            <v-card width="400">
                <div
                    class="d-flex justify-content-between align-items-center p-2"
                >
                    <div
                        @click="openLeadDetails"
                        class="cursor-pointer text-decoration-underline"
                    >
                        <strong>Show Lead Details</strong>
                    </div>
                    <v-btn
                        density="compact"
                        flat
                        icon="mdi-close"
                        @click="dialog = false"
                    ></v-btn>
                </div>
                <hr class="m-0" />
                <div style="max-height: 300px; overflow-y: auto">
                    <v-list
                        :items="drill_down_list"
                        item-title="title"
                        item-value="id"
                        @click:select="handleModalSelecedItem"
                    ></v-list>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import _ from "lodash";
import { fetchLeadPivot } from "./service";
// import CountryImg from "./CountryImg.vue";
import ReportPermission from "./modules/report.permission";

export default {
    props: {
        columns: Array,
        rows: Array,
        drill_down_list: Array,
        filters: Object,
        tab: String,
        loading: Boolean,
        isRoot: Boolean,
    },
    // components: {
    //     CountryImg,
    // },
    computed: {
        isLoading() {
            return this.innerLoading || this.loading;
        },
        updatedColumn() {
            if (this.sortedColumn && this.sortedColumn.length > 0) {
                return {
                    title: this.columns.find(
                        (column) => column.key === this.sortedColumn[0]["key"],
                    )?.title,
                    key: this.sortedColumn[0]["key"],
                    order: this.sortedColumn[0]["order"],
                };
            }
            return {
                title: null,
                key: null,
                order: null,
            };
        },
    },
    data() {
        return {
            innerLoading: false,
            expanded: [],
            dialog: false,
            items: [],
            group_by: "",
            selectedSlotData: null,
            nestedFilters: {},
            sortedColumn: null,
            report_permission: new ReportPermission()
        };
    },
    methods: {
        sortArrayOfObjectKeyString(array, key, order) {
            const lastElement = array.pop();
            array = array.sort((a, b) => {
                return a[key].localeCompare(b[key]);
            });
            if (order === "desc") {
                array.reverse();
            }
            array.push(lastElement);
        },
        sortArrayOfObjectKeyNumber(array, key, order) {
            const lastElement = array.pop();
            array.sort((a, b) => {
                return a[key] - b[key];
            });
            if (order === "desc") {
                array.reverse();
            }
            array.push(lastElement);
        },
        handleSortBy(obj) {
            this.sortedColumn = obj;
            if (obj.length > 0) {
                if (typeof this.rows[0][obj[0]["key"]] === "number") {
                    console.log("sortArrayOfObjectKeyNumber");
                    this.sortArrayOfObjectKeyNumber(
                        this.rows,
                        obj[0]["key"],
                        obj[0]["order"],
                        this.columns,
                    );
                } else if (typeof this.rows[0][obj[0]["key"]] === "string") {
                    console.log("sortArrayOfObjectKeyString");
                    this.sortArrayOfObjectKeyString(
                        this.rows,
                        obj[0]["key"],
                        obj[0]["order"],
                        this.columns,
                    );
                }
            }
            // console.log("handleSortBy", obj);
            // console.log("this.rows", this.rows.length);
            // console.log("this.rows", typeof this.rows[0].advertiser_id);
            // console.log("this.rows", typeof this.rows[0].total);
            // console.log("this.rows", typeof this.rows[0].advertisers);
        },
        openLeadDetails() {
            // console.log("openLeadDetails", this.selectedSlotData, this.filters);
            // const payload = {};

            // for (let i = 0; i < this.filters["parents"].length - 1; i++) {
            //     const parent = this.filters["parents"][i];
            //     const value = this.filters[parent][0];
            //     payload[parent] = value;
            // }
            const payload = { ...this.filters };
            const lastParent = this.filters["parents"].slice(-1)[0];
            const lastParentValue = this.selectedSlotData.item[lastParent];
            payload[lastParent] = [lastParentValue];
            console.log("payload", payload);
            // encode this payload as a query param to the leads view open in new tab
            const encodedPayload = encodeURIComponent(JSON.stringify(payload));
            this.openLinkInNewTab({
                name: "LeadsView",
                query: { filters: encodedPayload },
            });
            this.dialog = false;
        },
        openLinkInNewTab(route) {
            const routeData = this.$router.resolve(route);
            window.open(routeData.href, "_blank");
        },
        openDefaultDrillDown(slotData) {
            this.selectedSlotData = slotData;
            this.handleModalSelecedItem({ id: "country_id", title: "Country" });
        },
        openDialog(slotData) {
            this.selectedSlotData = slotData;
            this.dialog = true;
        },
        async actionColumn(slotData) {
            const indexExpanded = this.expanded.findIndex(
                (i) => i === slotData.item?.id,
            );
            if (indexExpanded > -1) {
                this.expanded.splice(indexExpanded, 1);
            } else {
                this.expanded.push(slotData.item?.id);
            }
            await this.toggleNestedTable(slotData.item);
        },
        async toggleNestedTable(row) {
            if (!row.showNestedTable) {
                await this.fetchNestedTableData(row);
                row.showNestedTable = true;
            } else {
                row.showNestedTable = false;
                row.nestedColumns = [];
                row.nestedRows = [];
            }
        },
        handleModalSelecedItem(item) {
            this.group_by = item?.id;
            this.dialog = false;
            if (this.selectedSlotData) {
                this.actionColumn(this.selectedSlotData);
                this.selectedSlotData = null;
            }
        },
        async fetchNestedTableData(row) {
            this.innerLoading = true;
            if (!this.group_by) {
                this.innerLoading = false;
                return;
            }
            // Create a deep copy of the filters object to avoid modifying the parent data
            // const filtersCopy = _.cloneDeep(this.filters); // Or use JSON.parse(JSON.stringify(this.filters)) if you don't use lodash
            const filtersCopy = JSON.parse(JSON.stringify(this.filters)); // Or use JSON.parse(JSON.stringify(this.filters)) if you don't use lodash
            const lastParents = filtersCopy?.parents?.at(-1);


            const drillDownObj = {
                [lastParents]: row[lastParents] ? [row[lastParents]] : null,
            };
            const parents = filtersCopy?.parents || [];
            parents.push(this.group_by);
            // Create a new payload object to ensure the data isn't referenced elsewhere
            const payload = {
                ...filtersCopy,
                group_by: this.group_by,
                parents: parents,
                ...drillDownObj,
            };
            // Assign the cloned and modified payload to this.nestedFilters
            // this.nestedFilters = payload;
            // Initialize row data for the nested table
            row.nestedRows = [];
            row.nestedColumns = [];
            row.drillDownList = [];
            row.nestedFilters  = payload;
            // Fetch data for the nested table
            const response = await fetchLeadPivot(payload);
            if (!response) {
                this.innerLoading = false;
                return;
            }

            row.nestedRows = this.genrateRows(response?.data || []) || [];
            if (row.nestedRows.length > 0) {
                row.nestedRows.push({
                    ...response?.totals,
                    notAction: true,
                    [response?.headers[0]]: "Total",
                });
            }
            row.nestedColumns =
                this.generateColumns(response?.headers || []) || [];
            row.drillDownList =
                this.generateDrillDownList(
                    response?.next_group_by_drilldown_enum || [],
                ) || [];
            this.innerLoading = false;
        },
        genrateRows(rows) {
            return rows?.map((row, index) => {
                return {
                    ...row,
                    action: "",
                    id: index + 1,
                    showNestedTable: false,
                };
            });
        },
        generateColumns(headers) {
            let columns = headers?.map((header) => {
                const title = header
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");

                return {
                    title: title,
                    key: header,
                };
            });
            columns.splice(1, 0, { title: "", key: "action" });
            return columns;
        },
        generateDrillDownList(list) {
            return Object.entries(list)?.map(([id, title]) => ({ id, title }));
        },
        cellProps(data) {
            let sellStyle={}
            if (data.index === this.rows.length - 1) {
                sellStyle.fontWeight= "bold";
                sellStyle.backgroundColor= "#eeeeee";
            }
            if (data.column.key === 'profit') {
                // Conditionally set the background color for the "Age" column
                sellStyle.backgroundColor='rgb(182, 215, 168)';
            }
            return { style:sellStyle}
        },

        hideColumnsBasedOnPermission(columns) {
            const permissions = this.$store.getters.getUserPermisssion
            const modules = ["advertiser_id", "country_id", "offer", "affiliate_id", "created_at", "brand_status"]

            if ( modules.includes(this.tab) ) {
                return columns.filter( (item) => {
                    const permission = this.report_permission.hasPermissionToShow(this.tab, item.key, permissions)

                    if ( permission == true ) {
                        return item
                    }
                })

            } else {
                return columns
            }
        }

    },

};
</script>

<style scoped>
.custom-table {
    background: #fff;
}
.custom-table >>> thead {
    background-color: rgb(246 247 250);
}

.custom-table >>> .v-data-table-header__content {
    font-weight: bold;
    color: #0e1726 !important;
}
.nested-table-wrapper {
    padding: 0;
    margin: 0;
}

.nested-table-wrapper > * {
    width: 100%;
}

.nested-table-wrapper tr {
    width: 100%;
}

.nested-table-wrapper td {
    width: 100%;
}
</style>

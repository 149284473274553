<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <StandardBtn
                v-if="hasPermission('PUBLISHERS_CREATE')"
                type="create"
                @click="openCreateDialog"
            />
        </div>

        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-4">
                <v-text-field
                    label="Search"
                    v-model="searchTable"
                    hide-details
                    variant="outlined"
                    clearable
                    density="compact"
                />
            </v-col>
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(rows.length / pageSize)}`
                "
                :search="searchTable"
                height="65vh"
                :stickyHeader="true"
                :columns="visibleColumns"
                :rows="rows"
                sortColumn="created_at"
                sortDirection="desc"
                :sortable="true"
                :loading="loading"
                :pageSize="getPageSizeDefault('publisher-main')"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- Advertiser Name -->.
                <template #advertiser_name="{ value }">
                    <a
                        :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.advertiser_name }}
                        ({{ value.advertiser_id }})
                    </a>
                </template>

                <!-- Country -->
                <template #country_name="{ value }">
                    <div class="d-flex items-center gap-2">
                        <!-- Dynamically get the image source based on the country code -->
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            onError="this.src='/img/flags/default.png'"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>
                <!-- Affiliate Name -->
                <template #affiliate_name="{ value }">
                    <a
                        :href="`${apiUrl}/affiliates/${value.affiliate_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.affiliate_name }}
                        ({{ value.affiliate_id }})
                    </a>
                </template>

                <!-- Conversion Rate -->
                <template #cr="{ value }"> {{ value.cr }}% </template>

                <!-- Affiliate Conversion Rate -->
                <template #aff_cr="{ value }"> {{ value.aff_cr }}% </template>

                <!-- Brand Status -->
                <template #status="{ value }">
                    <span v-if="value.status === 'active'">
                        <span class="badge bg-success">{{ value.status }}</span>
                    </span>
                    <span v-else>
                        <span class="badge bg-danger">{{ value.status }}</span>
                    </span>
                </template>

                <!-- Late FTD -->
                <template #late_ftd="{ value }">
                    <span v-if="value.late_ftd === 'Yes'">
                        <span class="badge bg-success">{{
                            value.late_ftd
                        }}</span>
                    </span>
                    <span v-else>
                        <span class="badge bg-danger">{{
                            value.late_ftd
                        }}</span>
                    </span>
                </template>

                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn
                            v-if="hasPermission('PUBLISHERS_EDIT')"
                            type="edit"
                            @click="
                                () =>
                                    $router.push({
                                        name: 'PublisherEditView',
                                        params: { id: value.id },
                                    })
                            "
                        />
                        <StandardBtn
                            v-if="hasPermission('PUBLISHERS_DELETE')"
                            type="delete"
                            @click="deleteLeads(value.id)"
                        />
                    </div>
                </template>
            </Vue3Datatable>
        </div>

        <v-dialog
            v-model="isCreatePublisherDialog"
            :disabled="create_loading"
            :loading="create_loading"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title>Create Publisher</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Name"
                                v-model="name"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-select
                                label="Autologin Interval"
                                v-model="autologin_interval"
                                :items="autologin_interval_items"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                label="Locate Leads By"
                                v-model="locate_leads_by"
                                :items="locate_leads_by_items"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                label="Tracking Domain"
                                v-model="tracking_domain"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Offer URL"
                                v-model="offer_url"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-switch
                                label="Status"
                                v-model="status"
                                :true-value="'active'"
                                :false-value="'inactive'"
                                hide-details
                                color="primary"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="isCreatePublisherDialog = false">
                        Cancel
                    </v-btn>

                    <v-btn
                        color="primary"
                        :loading="create_loading"
                        @click="createPublisher"
                        >Create</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useStore } from "vuex";
import { createPublishersRequest } from "./service";
import { usePermissions } from "@/composables/usePermissions";
import PageSizeService from "@/common/pagesize.service";

export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({}),
        },
    },

    setup(props) {
        /** Page Default Class */
        const pagesize_service = new PageSizeService()

        const { hasPermission } = usePermissions();
        const store = useStore();
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);
        const pageSize = ref(10);
        const page = ref(1);

        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
            pagesize_service.methods.setPageSizeDefault("publisher-main", value)
        }

        const getPageSizeDefault = (module_name) => {
            return pagesize_service.methods.getPageSizeDefault(module_name).pagesize
        }

        const isCreatePublisherDialog = ref(false);
        const name = ref("");
        const status = ref("active");
        const autologin_interval = ref("days");
        const autologin_interval_items = ref(["days"]);
        const locate_leads_by = ref("system");
        const locate_leads_by_items = ref(["ip", "system", "phone"]);
        const tracking_domain = ref("");
        const offer_url = ref("");
        const create_loading = ref(false);

        function resetCreateDialog() {
            name.value = "";
            status.value = "active";
            autologin_interval.value = "days";
            locate_leads_by.value = "system";
            tracking_domain.value = "";
            offer_url.value = "";
        }

        function openCreateDialog() {
            create_loading.value = false;
            resetCreateDialog();
            isCreatePublisherDialog.value = true;
        }

        async function createPublisher() {
            create_loading.value = true;
            const payload = {
                name: name.value,
                status: status.value,
                autologin_interval: autologin_interval.value,
                locate_leads_by: locate_leads_by.value,
                tracking_domain: tracking_domain.value,
                offer_url: offer_url.value,
            };

            const response = await createPublishersRequest(payload);
            if (!response) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to create publisher. Please try again.",
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Publisher created successfully.",
                });
            }
            isCreatePublisherDialog.value = false;
            create_loading.value = false;
            // reload page
            window.location.reload();
        }

        const searchTable = ref("");
        const allColumns = ref([
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                field: "status",
                title: "Status",
                visible: true,
                sortable: true,
            },
            {
                field: "block_duplicate_leads",
                title: "Autologin Interval",
                visible: true,
                sortable: true,
            },
            {
                field: "block_duplicate_leads",
                title: "Block Duplicate Leads",
                visible: true,
                sortable: true,
            },
            {
                field: "locate_leads_by",
                title: "Locate Leads By",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const countries = computed(() => store.getters.getCountries);

        const getCountryCode = (countryName) => {
            const country = countries.value.find((c) => c.name === countryName);
            return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
        };

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }

        function acceptLead(id) {
            console.log("Accept Lead: " + id);
        }

        function deleteLeads(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const url = `/api/publishers/${id}`;
                    try {
                        // Make the POST request with axios
                        const response = await axios.delete(url, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        // Check if the response has a message
                        if (response.data && response.data.message) {
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted",
                                text: response.data.message,
                            });
                            rows.value = rows.value.filter(
                                (row) => row.id !== id,
                            );
                        } else {
                            throw new Error("Unexpected response format");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });

                        console.error("Failed to delete:", error);
                    }
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        const filteredRows = computed(() => {
            return rows.value.filter((row) => {
                return Object.keys(props.filterData.filters || {}).every(
                    (key) => {
                        return (
                            !props.filterData.filters[key] ||
                            row[key] === props.filterData.filters[key]
                        );
                    },
                );
            });
        });

        async function fetchDataWithFilter() {
            const url = `/api/publishers`;

            console.log("Fetching data from URL:", url);

            try {
                const { data } = await axios.get(url);
                rows.value = data; // Assuming rows is a reactive variable in Vue 3

                console.log("Received data:", data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            fetchDataWithFilter();

            document.addEventListener("click", handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            hasPermission,
            rows,
            page,
            pageSize,
            handlePageChange,
            handlePageSizeChange,
            allColumns,
            searchTable,
            visibleColumns,
            filteredRows,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            apiUrl,
            acceptLead,
            deleteLeads,
            getCountryCode,

            // Create Dialog
            isCreatePublisherDialog,
            openCreateDialog,
            resetCreateDialog,
            createPublisher,
            name,
            status,
            autologin_interval,
            autologin_interval_items,
            locate_leads_by,
            locate_leads_by_items,
            tracking_domain,
            offer_url,
            create_loading,

            getPageSizeDefault
        };
    },
};
</script>

<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>

<template>
    <div class="">
        <div
            class="dropdown d-flex gap-2 justify-content-between"
            ref="dropdown"
        >
            <button
                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                @click="toggleDropdown"
            >
                Columns
            </button>
            <div
                v-show="dropdownOpen"
                class="dropdown-content"
                style="margin-top: 0px; z-index: 11"
            >
                <label v-for="col in allColumns" :key="col.field">
                    <input
                        type="checkbox"
                        :checked="col.visible"
                        @change="() => toggleColumnVisibility(col)"
                    />
                    {{ col.title }}
                </label>
            </div>
            <div>
                <StandardBtn type="create" :text="true" custom-icon="mdi-filter" custom-color="indigo-darken-1">
                    Add New Campaign
                </StandardBtn>
            </div>
        </div>
        <v-data-table
            :fixed-header="true"
            :fixed-footer="true"
            :sticky="true"
            height="60vh"
            color="#f00"
            :headers="visibleColumns"
            :items="rows"
            :loading="loading"
            :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
            v-model="slectedTableRows"
            class="elevation-1"
            v-model:items-per-page="pageSize"
            :hide-default-footer="true"
            @update:itemsPerPage="handlePageSizeChange"
            @update:page="handlePageChange"
        >
            <template v-slot:bottom="{ pagination, options, updateOptions }">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="ml-5">{{ paginationInfo }}</div>
                    <v-data-table-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                </div>
            </template>
            <template #[`item.steps`]="{ item }">
                <div class="primary-text">
                    {{ item.steps }}
                </div>
            </template>
            <template #[`item.status`]="{ item }">
                <span
                    v-if="item.status === 'active'"
                    class="badge badge-success"
                >
                    {{ item.status }}
                </span>
                <span
                    v-else-if="item.status === 'inactive'"
                    class="badge badge-danger"
                >
                    {{ item.status }}
                </span>
                <span v-else class="badge badge-info">
                    {{ item.status }}
                </span>
            </template>
            <template #[`item.actions`]="{}">
                <div class="d-flex gap-2">
                    <StandardBtn
                        type="custom"
                        variant="flat"
                        custom-icon="mdi-information"
                        custom-color="primary"
                        text
                    >
                        Details
                    </StandardBtn>
                    <StandardBtn
                        type="custom"
                        variant="flat"
                        custom-icon="mdi-eye"
                        custom-color="warning"
                        text
                    >
                        Logs
                    </StandardBtn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
export default {
    setup() {
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const rows = ref([
            {
                id: 513,
                name: "Affect Do Not Touch",
                steps: 1,
                status: "active",
            },
            {
                id: 493,
                name: "test",
                steps: 0,
                status: "inactive",
            },
            {
                id: 843,
                name: "1343_https://blastgarden.com",
                steps: 1,
                status: "active",
            },
            {
                id: 483,
                name: "test123",
                steps: 0,
                status: "inactive",
            },
            {
                id: 473,
                name: "test",
                steps: 0,
                status: "inactive",
            },
            {
                id: 453,
                name: "dffd",
                steps: 1,
                status: "complete",
            },
            {
                id: 443,
                name: "Import1",
                steps: 1,
                status: "complete",
            },
        ]);
        const allColumns = ref([
            {
                value: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                value: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                value: "steps",
                title: "Steps",
                visible: true,
                sortable: true,
            },
            {
                value: "status",
                title: "Status",
                visible: true,
                sortable: true,
            },
            {
                value: "actions",
                title: "Actions",
                visible: true,
                sortable: true,
            },
        ]);

        const pageSize = ref(100);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }
        const paginationInfo = computed(() => {
            if (Math.ceil(rows.value.length / pageSize.value) === 0) {
                return "";
            } else if (pageSize.value === -1) {
                return `Page 1 of 1`;
            } else {
                return `Page ${page.value} of ${Math.ceil(rows.value.length / pageSize.value)}`;
            }
        });

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find(
                (col) => col.value === field.value,
            );
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });
        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }
        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
        });
        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });
        return {
            allColumns,
            visibleColumns,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            rows,
            pageSize,
            page,
            handlePageChange,
            handlePageSizeChange,
            paginationInfo,
        };
    },
};
</script>

<style scoped>
::v-deep thead > tr,
::v-deep th {
    background: black !important;
    color: white;
}
</style>

// src/store/index.js
import { createStore } from "vuex";
import axios from "axios"; // Ensure axios is imported

const store = createStore({
    state: {
        user: null,
        filterData: null,
        countries: [], // State to hold countries
        timeZones: [], // State to hold time zones
        hideFilterOptions: [],

        leadColumns:[],

        userSettings:null,

        pendingFtdCount:null

       
    },
    getters: {
        getFilterData: (state) => state.filterData,
        getCountries: (state) => state.countries, // Getter to access countries
        getTimeZones: (state) => state.timeZones, // Getter to access time zones
        getUser: (state) => state.user, // Getter to access user
        getUserPermisssion: (state) => state.user?.permissions || [],
        getHideFilterOptions: (state) => state.hideFilterOptions,

        getUserSettings: (state) => state.userSettings ,

        getLastPendiFtdDate: (state) => state.userSettings?.extras?.last_pending_ftd_date,
        getPendingFtdCount: (state) => state.pendingFtdCount,
    },
    mutations: {
        setFilterData(state, value) {
            state.filterData = value;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setTimeZones(state, timeZones) {
            // Mutation to set time zones
            state.timeZones = timeZones;
        },
        setUser(state, user) {
            state.user = user;
        },
        setHideFilterOptions(state, options) {
            state.hideFilterOptions = options;
        },

        setUserSettings(state, settings) {
            state.userSettings = settings;
        },


        setLastPendiFtdDate(state, date) {
            state.userSettings.extras.last_pending_ftd_date = date;
        },
        setPendingFtdCount(state, count) {
            state.pendingFtdCount = count;
        },
        
    },
    actions: {
        setFilterData({ commit }, payload) {
            commit("setFilterData", payload);
        },
        async fetchCountries({ commit }) {
            try {
                const res = await axios.get("/api/countries"); // Fetch countries from API
                commit("setCountries", res.data); // Commit the countries to the state
            } catch (error) {
                console.error("Error fetching countries:", error); // Handle error
            }
        },
        async fetchTimeZones({ commit }) {
            try {
                const res = await axios.get("/api/timezone"); // Fetch time zones from API
                commit("setTimeZones", res.data); // Commit the time zones to the state
            } catch (error) {
                console.error("Error fetching time zones:", error); // Handle error
            }
        },
        setUser({ commit }, user) {
            localStorage.setItem("user_data", JSON.stringify(user));
            commit("setUser", user);
        },
        setHideFilterOptions({ commit }, options) {
            commit("setHideFilterOptions", options);
        },

        async fetchUserSettings ({ commit, state }){
            try {
                const response = await axios.get("/api/user-settings/"+state.user.user_id);
                console.log("fetchUserSettings", response.data);
                commit("setUserSettings", response.data);
            } catch (error) {
                console.error(error);
            }
        }
        //page,column,tab
        // toggleColumnVisibility({state}, options) {
        //     if(options.tab){
        //         state.tablesColumns[page][tab]['visible'] = !state.tablesColumns[page][tab]['visible']
        //     }else{
        //         state.tablesColumns[page]['visible'] = !state.tablesColumns[page]['visible']
        //     }
        // }
    },
});

export default store;

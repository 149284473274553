import Swal from "sweetalert2";
import BlacklistService from "./blacklist.service";

export default class BlockListMethods {
    constructor(emits) {
        this.emits = emits
        this.blacklist_service = new BlacklistService()
    }

    methods = ( () => {
        const closeAddBlocklistModal = () => {
            this.emits("closeAddBlackListModal")
        }

        const saveBlacklistItem = async (data, blacklist_form) => {
            const validated = await blacklist_form.validate()
            // Validate the data if it is not empty
            if ( validated.valid ) {
                data.expires_at = new Date(data.expires_at).toLocaleDateString()
                data.hide_blocked = data.hide_blocked == true ? 1 : 0
                const response = await this.blacklist_service.blackList.addBlacklistItem(data)

                if ( response.success == "success" ) {
                    Swal.fire({
                        icon: "success",
                        title: "Add New Blacklist Item",
                        text: "A new blacklist item was successfully added.",
                    }).then( () => {
                        window.location.reload()
                    })
                }
            }
        }

        const deleteBlacklistItem = async (id) => {
            const response = await this.blacklist_service.blackList.deleteBlacklistItem(id)

            if ( response.success == "success" ) {
                Swal.fire({
                    icon: "success",
                    title: "Delete a Blacklist Item",
                    text: response.message,
                }).then( () => {
                    window.location.reload()
                })
            }
        }

        const updateBlacklistItem = async (id, data) => {
            data.expires_at = new Date(data.expires_at).toLocaleDateString()
            data.hide_blocked = data.hide_blocked == true ? 1 : 0
            const response = await this.blacklist_service.blackList.editBlacklistItem(id, data)

            if ( response.success == "success" ) {
                Swal.fire({
                    icon: "success",
                    title: "Edit Blacklist Item",
                    text: response.message,
                }).then( () => {
                    window.location.reload()
                })
            }
        }

        const openBlacklistItemModelForEdit = (data) => {
            this.emits("editBlacklistItem", data)
        }

        return {
            closeAddBlocklistModal,
            saveBlacklistItem,
            deleteBlacklistItem,
            updateBlacklistItem,
            openBlacklistItemModelForEdit
        }
    })
}

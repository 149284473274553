<template>
     <v-container fluid class="p-0">
        <v-row class="px-0">
            <!-- Dropdown for column visibility -->
            <v-col cols="12" class="px-0">
                <div class="d-flex justify-content-between align-items-center mt-2">
                    <div class="dropdown" ref="dropdown">
                        <div
                            v-show="dropdownOpen"
                            class="dropdown-content"
                            style="margin-top: 0px"
                        >
                            <label v-for="col in allColumns" :key="col.field">
                                <input
                                    type="checkbox"
                                    :checked="col.visible"
                                    @change="toggleColumnVisibility(col.field)"
                                />
                                {{ col.title }}
                            </label>
                        </div>
                    </div>
                    <StandardBtn
                        v-if="hasPermission('DISTRIBUTIONS_CREATE')"
                        type="create"
                        @click="
                            openDistributionModal({ id: null, page: 1, create: true })
                        "
                    />
                </div>
            </v-col>
            <v-col cols="12" class="pt-0 px-0">
                <ul class="nav nav-tabs" id="tableTabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            :class="{
                                'active elevation-2': activeTab === 'all',
                            }"
                            id="all-tab"
                            type="button"
                            @click="activeTab = 'all'"
                        >
                            All
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            :class="{
                                active: activeTab === 'country',
                            }"
                            id="country-tab"
                            type="button"
                            @click="activeTab = 'country'"
                        >
                            Country
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            :class="{
                                active: activeTab === 'publisher',
                            }"
                            id="publisher-tab"
                            type="button"
                            @click="activeTab = 'publisher'"
                        >
                            Publisher
                        </button>
                    </li>
                </ul>
                <!-- Table displayed based on the active tab -->
        
                <div class="bh-table-responsive table-container mb-5  bg-white">
                    <!-- Table for Country -->
                    <!-- :search="searchTable" -->
                    <v-data-table
                        :items-per-page="50"
                        v-if="activeTab === 'country'"
                        :fixed-header="true"
                        :sticky="true"
                        :headers="countryColumns"
                        :items="countryRows"
                        :loading="loading"
                        :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
                        v-model:expanded="expanded['country']"
                        class="elevation-1 pt-2"
                        hide-default-footer
                        :height="'calc(100vh - 280px)'"
                    >
                        <template v-slot:bottom="{ pagination, options, updateOptions }">
                            <div
                                class="d-flex justify-end align-items-center flex-wrap"
                            >
                                <!-- <div class="mt-2 text-nowrap col-12 col-md-4">
                                    <v-text-field
                                        label="Search"
                                        v-model="searchTable"
                                        hide-details
                                        variant="outlined"
                                        clearable
                                        density="compact"
                                    />
                                </div> -->
                                <div class="ml-3">
                                    <v-data-table-footer
                                        :pagination="pagination"
                                        :options="options"
                                        @update:options="updateOptions"
                                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                    />
                                </div>
                            </div>
                        </template>
                        <template #[`item.country_name`]="{ item }">
                            <CountryInfo :name="item.country_name" />
                        </template>
                        <template #[`item.actions`]="slotData">
                            <StandardBtn
                                type="custom"
                                text
                                :icon="false"
                                @click="toggleNestedTable(slotData, 'country')"
                            >
                                {{ slotData.item.count }}
                                Distribution{{ slotData.item.count > 1 ? "s" : "" }}
                            </StandardBtn>
                        </template>
                        <template v-slot:expanded-row="{ item }">
                            <tr>
                                <td colspan="100%" class="nested-table-wrapper">
                                    <v-card class="my-4">
                                        <Vue3Datatable
                                            :pageSize="50"
                                            :columns="item.nestedColumns"
                                            sortColumn="updated_at"
                                            sortDirection="desc"
                                            :rows="item.nestedRows"
                                            :sortable="true"
                                            skin="bh-table-bordered bh-table-responsive"
                                        >
                                            <template #is_active_open="{ value }">
                                                <div class="flex gap-4">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="circle"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        class="mr-1 fa-xs margin_top_1 svg-inline--fa fa-circle fa-w-16"
                                                        :class="
                                                            value.is_active_open ===
                                                            'true'
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        "
                                                        style="
                                                            width: 1rem;
                                                            height: 1rem;
                                                        "
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </template>
                                            <!-- Advertiser Name -->
                                            <template #advertiser_name="{ value }">
                                                <a
                                                    :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                                                    class="text-decoration-underline"
                                                >
                                                    {{ value.advertiser_name }}
                                                    ({{ value.advertiser_id }})
                                                </a>
                                            </template>
                                            <!-- Country -->
                                            <template #country_name="{ value }">
                                                <CountryInfo
                                                    :name="value.country_name"
                                                />
                                            </template>
                                            <!-- Actions -->
                                            <template #actions="{ value }">
                                                <div class="d-flex gap-2">
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_EDIT',
                                                            )
                                                        "
                                                        type="view"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 2,
                                                                create: false,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_EDIT',
                                                            )
                                                        "
                                                        type="edit"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 1,
                                                                create: false,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_CREATE',
                                                            )
                                                        "
                                                        type="copy"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 1,
                                                                create: true,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_DELETE',
                                                            )
                                                        "
                                                        type="delete"
                                                        @click="deleteLeads(value.id)"
                                                    />
                                                </div>
                                            </template>
                                        </Vue3Datatable>
                                    </v-card>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

                    <!-- Table for Publisher -->
                    <v-data-table
                        :items-per-page="50"
                        v-if="activeTab === 'publisher'"
                        :fixed-header="true"
                        :sticky="true"
                        :headers="publisherColumns"
                        :items="publisherRows"
                        :loading="loading"
                        :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
                        v-model:expanded="expanded['publisher']"
                        class="elevation-1 pt-2"
                        hide-default-footer
                        :height="'calc(100vh - 280px)'"
                    >
                        <template v-slot:bottom="{ pagination, options, updateOptions }">
                            <v-data-table-footer
                                :pagination="pagination"
                                :options="options"
                                @update:options="updateOptions"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            />
                        </template>
                        <template #[`item.publisher_name`]="{ item }">
                            {{ item.publisher_name || "Global" }}
                        </template>
                        <template #[`item.actions`]="slotData">
                            <StandardBtn
                                type="custom"
                                text
                                :icon="false"
                                @click="toggleNestedTable(slotData, 'publisher')"
                            >
                                {{ slotData.item.count }}
                                Distribution{{ slotData.item.count > 1 ? "s" : "" }}
                            </StandardBtn>
                        </template>
                        <template v-slot:expanded-row="{ item }">
                            <tr>
                                <td colspan="100%" class="nested-table-wrapper">
                                    <v-card class="my-4">
                                        <Vue3Datatable
                                            :pageSize="50"
                                            :columns="item.nestedColumns"
                                            sortColumn="updated_at"
                                            sortDirection="desc"
                                            :rows="item.nestedRows"
                                            :sortable="true"
                                            skin="bh-table-bordered bh-table-responsive"
                                        >
                                            <template #is_active_open="{ value }">
                                                <div class="flex gap-4">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="circle"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        class="mr-1 fa-xs margin_top_1 svg-inline--fa fa-circle fa-w-16"
                                                        :class="
                                                            value.is_active_open ===
                                                            'true'
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        "
                                                        style="
                                                            width: 1rem;
                                                            height: 1rem;
                                                        "
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </template>
                                            <!-- Advertiser Name -->
                                            <template #advertiser_name="{ value }">
                                                <a
                                                    :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                                                    class="text-decoration-underline"
                                                >
                                                    {{ value.advertiser_name }}
                                                    ({{ value.advertiser_id }})
                                                </a>
                                            </template>
                                            <!-- Country -->
                                            <template #country_name="{ value }">
                                                <CountryInfo
                                                    :name="value.country_name"
                                                />
                                            </template>
                                            <!-- Actions -->
                                            <template #actions="{ value }">
                                                <div class="d-flex gap-2">
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_EDIT',
                                                            )
                                                        "
                                                        type="view"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 2,
                                                                create: false,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_EDIT',
                                                            )
                                                        "
                                                        type="edit"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 1,
                                                                create: false,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_CREATE',
                                                            )
                                                        "
                                                        type="copy"
                                                        @click="
                                                            openDistributionModal({
                                                                id: value.id,
                                                                page: 1,
                                                                create: true,
                                                            })
                                                        "
                                                    />
                                                    <StandardBtn
                                                        v-if="
                                                            hasPermission(
                                                                'DISTRIBUTIONS_DELETE',
                                                            )
                                                        "
                                                        type="delete"
                                                        @click="deleteLeads(value.id)"
                                                    />
                                                </div>
                                            </template>
                                        </Vue3Datatable>
                                    </v-card>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <!-- DataTable for All -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        v-if="activeTab === 'all'"
                        class="mb-0 "
                    >
                        <v-text-field
                            label="Search"
                            v-model="searchTable"
                            hide-details
                            variant="outlined"
                            clearable
                            density="compact"
                        />
                    </v-col>
                    <Vue3Datatable
                        @pageChange="handlePageChange"
                        @pageSizeChange="handlePageSizeChange"
                        :paginationInfo="
                            'Showing {0} to {1} of {2} entries' +
                            ` | Page ${page} of ${Math.ceil(rows.length / pageSize)}`
                        "
                        :pageSize="pageSize"
                        :search="searchTable"
                        height="65vh"
                        :stickyHeader="true"
                        v-if="activeTab === 'all'"
                        :columns="allColumns"
                        sortColumn="updated_at"
                        sortDirection="desc"
                        :rows="rows"
                        :sortable="true"
                        :loading="loading"
                        skin="bh-table-bordered bh-table-responsive"
                    >
                        <template #is_active_open="{ value }">
                            <div class="flex gap-4">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    class="mr-1 fa-xs margin_top_1 svg-inline--fa fa-circle fa-w-16"
                                    :class="
                                        value.is_active_open === 'true'
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                    style="width: 1rem; height: 1rem"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                    ></path>
                                </svg>
                            </div>
                        </template>
                        <!-- Advertiser Name -->
                        <template #advertiser_name="{ value }">
                            <a
                                :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                                class="text-decoration-underline"
                            >
                                {{ value.advertiser_name }}
                                ({{ value.advertiser_id }})
                            </a>
                        </template>
                        <!-- Country -->
                        <template #country_name="{ value }">
                            <CountryInfo :name="value.country_name" />
                        </template>
                        <!-- Actions -->
                        <template #actions="{ value }">
                            <div class="d-flex gap-2">
                                <StandardBtn
                                    v-if="hasPermission('DISTRIBUTIONS_EDIT')"
                                    type="view"
                                    @click="
                                        openDistributionModal({
                                            id: value.id,
                                            page: 2,
                                            create: false,
                                        })
                                    "
                                />
                                <StandardBtn
                                    v-if="hasPermission('DISTRIBUTIONS_EDIT')"
                                    type="edit"
                                    @click="
                                        openDistributionModal({
                                            id: value.id,
                                            page: 1,
                                            create: false,
                                        })
                                    "
                                />
                                <StandardBtn
                                    v-if="hasPermission('DISTRIBUTIONS_CREATE')"
                                    type="copy"
                                    @click="
                                        openDistributionModal({
                                            id: value.id,
                                            page: 1,
                                            create: true,
                                        })
                                    "
                                />
                                <StandardBtn
                                    v-if="hasPermission('DISTRIBUTIONS_DELETE')"
                                    type="delete"
                                    @click="deleteLeads(value.id)"
                                />
                            </div>
                        </template>
                    </Vue3Datatable>
                    <!-- <Vue3Datatable 
                    v-if="nestedRow.length > 0"
                    :columns="allColumns"
                    :rows="nestedRow"
                    :sortable="true"
                    :stickyHeader="true"
                    :loading="loading"
                    skin="bh-table-bordered bh-table-responsive "
                    >
                    </Vue3Datatable> -->
                    <!-- <div
                        class="modal fade"
                        id="editModal"
                        tabindex="-1"
                        aria-labelledby="editModalLabel"
                        aria-hidden="true"
                        ref="editModal"
                    >
                        <EditComponent :editId="editId" />
                    </div> -->
                    <!-- <div
                        class="modal fade"
                        id="createModal"
                        tabindex="-1"
                        aria-labelledby="creatModalLabel"
                        aria-hidden="true"
                        ref="createModal"
                    >
                        <CreateComponent />
                    </div> -->
                </div>
            </v-col>
        </v-row>
    </v-container>
    <CreateDistributionModal
        v-if="isCreateDistributionModal"
        @close="isCreateDistributionModal = false"
        :pageNumber="distributionModalPage"
        :distributionId="distributionUpdateId"
        :distributionData="distributionData"
    />
</template>
<script>
import { ref, computed, onMounted, nextTick, watch } from "vue";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
// import { Modal } from "bootstrap";
// import EditComponent from "./EditComponent.vue";
// import CreateComponent from "./CreateComponent.vue";
import CreateDistributionModal from "./CreateDistributionModal.vue";
import {
    fetchDistributionById,
    deleteDistributionRequest,
    fetchDistributions,
} from "./service";
import { usePermissions } from "@/composables/usePermissions";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
        // EditComponent,
        // CreateComponent,
        CreateDistributionModal,
    },
    props: {
        filterData: { type: Object, default: () => ({}) },
        type: { type: String },
        id: { type: Number, default: null },
    },
    setup(props, { emit: Emit }) {
        const { hasPermission } = usePermissions();
        const loading = ref(false);
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);
        const searchTable = ref("");
        const pageSize = ref(50);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            console.log("handlePageSizeChange", value);
            pageSize.value = value;
        }
        const allColumns = ref([
            {
                field: "is_active_open",
                title: "",
                visible: true,
            },
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                field: "type",
                title: "Method",
                visible: true,
                sortable: true,
            },
            {
                field: "timezone_name",
                title: "Timezone",
                visible: true,
                sortable: true,
            },
            {
                field: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                field: "updated_at",
                title: "Updated",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);
        // const editModal = ref(null);
        // const createModal = ref(null);
        const editId = ref(null);
        // start distribution
        const isCreateDistributionModal = ref(false);
        const distributionModalPage = ref(1);
        const distributionUpdateId = ref(null);
        const distributionData = ref(null);
        async function openDistributionModal({ id, page, create }) {
            // console.log(id, page, create);
            distributionData.value = null;
            distributionModalPage.value = page || 1;
            distributionUpdateId.value = id;
            if (create) {
                distributionUpdateId.value = null;
            }
            if (!id) {
                isCreateDistributionModal.value = true;
                return;
            }
            // fetch the id data
            distributionData.value = await fetchDistributionById(id);
            if (create) {
                distributionData.value.step1.labelValue = null;
            }
            isCreateDistributionModal.value = true;
        }
        // end of distribution
        // function editDistribution(id) {
        //     editId.value = id;
        //     nextTick(() => {
        //         if (editModal.value) {
        //             const modalInstance = new Modal(editModal.value);
        //             modalInstance.show();
        //         }
        //     });
        // }
        const publisherColumns = ref([
            {
                value: "publisher_name",
                title: "Publisher Name",
                visible: true,
                sortable: true,
            },
            {
                value: "actions",
                title: "Distributions",
                visible: true,
            },
        ]);
        const countryColumns = ref([
            {
                value: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                value: "actions",
                title: "Distributions",
                visible: true,
            },
        ]);
        const expanded = ref({
            country: [],
            publisher: [],
        });
        const countryRows = computed(() => {
            // create a set from rows
            // const countrySet = new Set(
            //     rows.value.map((row) => row.country_name),
            // );
            const countryMap = new Map();
            for (const row of rows.value) {
                countryMap.set(
                    row.country_name,
                    (countryMap.get(row.country_name) || 0) + 1,
                );
            }
            // if countryName is null then put it at first place also it's count should be as it was earlier
            const nullCountry = countryMap.get(null);
            if (nullCountry) {
                countryMap.delete(null);
                const newCountryMap = new Map([
                    [null, nullCountry],
                    ...countryMap,
                ]);
                countryMap.clear();
                for (const [key, value] of newCountryMap) {
                    countryMap.set(key, value);
                }
            }
            return Array.from(countryMap).map(([countryName, count], index) => {
                return {
                    country_name: countryName,
                    actions: "",
                    count: count,
                    id: index + 1,
                    showNestedTable: false,
                };
            });
        });
        const publisherRows = computed(() => {
            // create a map from rows also count the repetition
            // const publisherSet = new Set(
            //     rows.value.map((row) => row.publisher_name),
            // );
            const publisherMap = new Map();
            for (const row of rows.value) {
                publisherMap.set(
                    row.publisher_name,
                    (publisherMap.get(row.publisher_name) || 0) + 1,
                );
            }
            const nullPublisher = publisherMap.get(null);
            if (nullPublisher) {
                publisherMap.delete(null);
                const newPublisherMap = new Map([
                    [null, nullPublisher],
                    ...publisherMap,
                ]);
                publisherMap.clear();
                for (const [key, value] of newPublisherMap) {
                    publisherMap.set(key, value);
                }
            }
            return Array.from(publisherMap).map(
                ([publisherName, count], index) => {
                    return {
                        publisher_name: publisherName,
                        actions: "",
                        count: count,
                        id: index + 1,
                        showNestedTable: false,
                    };
                },
            );
        });
        async function toggleNestedTable(slotData, type) {
            const row = slotData.item;
            const indexExpanded = expanded.value[type].findIndex(
                (i) => i === row.id,
            );
            if (indexExpanded > -1) {
                expanded.value[type].splice(indexExpanded, 1);
            } else {
                expanded.value[type].push(row.id);
            }
            if (!row.showNestedTable) {
                row.nestedColumns = allColumns.value;
                row.nestedRows = rows.value.filter(
                    (r) => r[`${type}_name`] === row[`${type}_name`],
                );
                row.showNestedTable = true;
            } else {
                row.showNestedTable = false;
                row.nestedColumns = [];
                row.nestedRows = [];
            }
        }
        const rows = ref([]);
        const nestedRow = ref([]);
        const dropdownOpen = ref(false);
        const activeTab = ref("all");
        const fetchDataWithFilter = async () => {
            try {
                const { data } = await axios.get(`/api/distributions`);
                if (props.type == "publisher") {
                    rows.value = data.filter(
                        (row) => row.publisher_id == props.id,
                    );
                } else if (props.type == "advertiser") {
                    rows.value = data.filter(
                        (row) =>
                            row.advertiser_id &&
                            row.advertiser_id.includes(props.id),
                    );
                } else {
                    rows.value = data;
                }
                //                 {
                //                     {
                //     "id": 140,
                //     "name": "test new data override and param",
                //     "type": "slot",
                //     "timezone_id": 276,
                //     "country_id": null,
                //     "advertiser_id": null,
                //     "publisher_id": 3,
                //     "country_name": null,
                //     "advertiser_name": null,
                //     "publisher_name": "Union",
                //     "updated_at": "2024-10-21 19:25:55",
                //     "timezone_name": "Asia/Tashkent",
                //     "is_active_open": "true"
                // }

                //     "type": "publisher",
                //     "filterData": {},
                //     "id": "22"
                // }
                // rows.value = data;
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        const toggleDistribution = (id) => {
            console.log(rows.value);
            console.log(id);
            let nestedRowData = rows.value.filter(
                (row) => row.country_id === id,
            );
            nestedRow.value = nestedRowData;
        };
        function deleteLeads(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // Make the POST request with axios
                        const data = await deleteDistributionRequest(id);
                        if (data) {
                            Emit("reloadApi");
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted",
                                text: "Distribution Successfully deleted",
                            });
                            rows.value = rows.value.filter(
                                (row) => row.id !== id,
                            );
                        } else {
                            throw new Error("Failed to delete");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });
                        console.error("Failed to delete:", error);
                    }
                }
            });
        }
        const toggleDropdown = () => {
            dropdownOpen.value = !dropdownOpen.value;
        };
        const toggleColumnVisibility = (field) => {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) column.visible = !column.visible;
        };
        async function fetchRowsByFilter(filterData) {
            const payload = {
                ...filterData,
            };
            rows.value = (await fetchDistributions(payload)) || [];
        }
        watch(
            () => props.filterData,
            (newValue) => {
                console.log("New filter value:", newValue);
                fetchRowsByFilter(newValue);
            },
            {
                deep: true,
            },
        );
        onMounted(async () => {
            fetchDataWithFilter();
            // Initialize Select2 once the data is loaded and DOM is updated
            nextTick(() => {
                initializeSelect2();
            });
        });
        function initializeSelect2() {
            $(".select2").select2({
                width: "100%",
            });
        }
        return {
            hasPermission,
            loading,
            rows,
            nestedRow,
            page,
            pageSize,
            handlePageChange,
            handlePageSizeChange,
            allColumns,
            searchTable,
            toggleColumnVisibility,
            dropdownOpen,
            toggleDropdown,
            activeTab,
            apiUrl,
            publisherColumns,
            publisherRows,
            countryColumns,
            countryRows,
            expanded,
            toggleNestedTable,
            toggleDistribution,
            deleteLeads,
            openDistributionModal,
            distributionData,
            distributionModalPage,
            distributionUpdateId,
            // editDistribution,
            editId,
            // editModal,
            // createModal,
            isCreateDistributionModal,
        };
    },
};
</script>
<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
.nested-table-wrapper {
    padding: 0;
    margin: 0;
}

.nested-table-wrapper > * {
    width: 100%;
}

.nested-table-wrapper tr {
    width: 100%;
}

.nested-table-wrapper td {
    width: 100%;
}
</style>

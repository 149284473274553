<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="dropdown" ref="dropdown"></div>

        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>

            <StandardBtn
                v-if="hasPermission('CAPS_CREATE')"
                type="create"
                @click="createRow"
            />
        </div>

        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-4">
                <v-text-field
                    label="Search"
                    v-model="searchTable"
                    hide-details
                    variant="outlined"
                    clearable
                    density="compact"
                />
            </v-col>
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(filteredRows.length / params.pagesize)}`
                "
                height="65vh"
                :stickyHeader="true"
                :columns="visibleColumns"
                :rows="filteredRows"
                :loading="loading"
                :pageSize="params.pagesize"
                :pageSizeOptions="[10, 15, 30, 50, 100, 200, 500, 1000]"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- :sortable="true" -->
                <!-- paginationInfo="{0} to {1} of {2}" -->
                <template #interval="{ value }">
                    <v-chip class="ma-1 bg-primary" label>
                        {{ value.interval }}
                    </v-chip>
                </template>
                <template #type="{ value }">
                    <v-chip class="ma-1 bg-secondary" label>
                        {{ value.type }}
                    </v-chip>
                </template>
                <template #filled="{ value }">
                    <!-- {{  }} -->
                    <v-tooltip :text="`${value.filled}/${value.cap}`">
                        <template v-slot:activator="{ props }">
                            <v-progress-circular
                                v-bind="props"
                                :model-value="
                                    calculateProgress(value.filled, value.cap)
                                "
                                :size="50"
                                :rotate="360"
                                :width="10"
                                :color="
                                    calculateProgress(value.filled, value.cap) >
                                    80
                                        ? 'success'
                                        : calculateProgress(
                                                value.filled,
                                                value.cap,
                                            ) >= 50
                                          ? 'warning'
                                          : 'error'
                                "
                            >
                                <span class="x-small">
                                    {{ value.filled }}
                                </span>
                            </v-progress-circular>
                        </template>
                    </v-tooltip>
                    &nbsp; / {{ value.cap }}
                </template>
                <template #advertisers="{ value }">
                    <v-chip
                        v-for="advertiser in value.advertisers"
                        :key="advertiser"
                        class="ma-1"
                        label
                    >
                        {{ advertiser.name }}
                    </v-chip>
                </template>
                <template #affiliates="{ value }">
                    <v-chip
                        v-for="affiliate in value.affiliates"
                        :key="affiliate"
                        class="ma-1"
                        label
                    >
                        {{ affiliate.name }}
                    </v-chip>
                </template>
                <template #reset_at="{ value }">
                    {{ value.reset_at }}
                </template>

                <template #countries="{ value }">
                    <template v-if="value.countries.length > 0">
                        <span
                            v-for="(country, index) in value.countries"
                            :key="country"
                            class="ma-1"
                            label
                        >
                            <CountryInfo :id="country.id" />
                            <span v-if="index !== value.countries?.length - 1">
                                &comma;
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <CountryInfo :id="null" />
                    </template>
                </template>
                <template #timezone="{ value }">
                    <v-chip class="ma-1" label>
                        {{ value.timezone.name }}
                    </v-chip>
                </template>
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn
                            v-if="hasPermission('CAPS_EDIT')"
                            type="edit"
                            @click="editCapsRow(value.id)"
                        />
                        <StandardBtn
                            v-if="hasPermission('CAPS_DELETE')"
                            type="delete"
                            @click="deleteCapsRow(value.id)"
                        />
                    </div>
                </template>
            </Vue3Datatable>
        </div>

        <!-- Start Modal -->
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="editId === null"
                        >Add New Cap</span
                    >
                    <span class="headline" v-else>Edit Cap</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    label="Type"
                                    v-model="type"
                                    :items="['Lead', 'FTD']"
                                    variant="outlined"
                                    :rules="[
                                        (value) =>
                                            !!value ||
                                            'Please select a valid type',
                                    ]"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    label="Interval"
                                    v-model="interval"
                                    variant="outlined"
                                    :items="['Daily', 'Interval']"
                                    :rules="[
                                        (value) =>
                                            !!value ||
                                            'Please select a valid interval',
                                    ]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="resetAt"
                                    label="Enter time (HH:MM)"
                                    placeholder="HH:MM"
                                    variant="outlined"
                                    :rules="[
                                        (value) =>
                                            validateTime(value) ||
                                            'Please enter a valid time',
                                    ]"
                                    prepend-inner-icon="mdi-clock-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-autocomplete
                                    label="Timezone"
                                    v-model="timezone"
                                    :items="timezones"
                                    item-title="name"
                                    item-value="id"
                                    variant="outlined"
                                    :rules="[
                                        (value) =>
                                            !!value ||
                                            'Please select a valid timezone',
                                    ]"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Cap"
                                    v-model="cap"
                                    variant="outlined"
                                    type="number"
                                    :rules="[
                                        (value) =>
                                            (value >= 0 &&
                                                (value === 0 || !!value)) ||
                                            'Please enter a valid cap',
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    label="Countries"
                                    v-model="selectedCountry"
                                    variant="outlined"
                                    :items="countries"
                                    item-title="name"
                                    item-value="id"
                                    multiple
                                    clearable
                                >
                                    <!-- :rules="[
                                        (value) =>
                                            value.length > 0 ||
                                            'Please select a valid country',
                                    ]" -->
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <!-- {{ item.raw }} -->
                                        <v-chip
                                            small
                                            @click="
                                                selectedCountry.splice(index, 1)
                                            "
                                            close
                                            class="p-2"
                                        >
                                            <img
                                                :src="item.raw.country_icon"
                                                :alt="item.raw.code"
                                                class="img-flags"
                                            />
                                            &nbsp;
                                            {{ item.raw.name }}
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="selectedPublishers"
                                    :items="publishers"
                                    label="Publishers"
                                    item-title="name"
                                    item-value="id"
                                    multiple
                                    variant="outlined"
                                    clearable
                                >
                                    <!-- :rules="[
                                        (value) =>
                                            !!value.length ||
                                            'Please select at least one status',
                                    ]" -->
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <v-chip
                                            small
                                            @click="
                                                selectedPublishers.splice(
                                                    index,
                                                    1,
                                                )
                                            "
                                            close
                                        >
                                            {{ item.title }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="selectedAdvertisers"
                                    :items="advertisers"
                                    label="Advertisers"
                                    item-title="name"
                                    item-value="id"
                                    multiple
                                    variant="outlined"
                                    clearable
                                >
                                    <!-- :rules="[
                                        (value) =>
                                            !!value.length ||
                                            'Please select at least one status',
                                    ]" -->
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <v-chip
                                            small
                                            @click="
                                                selectedAdvertisers.splice(
                                                    index,
                                                    1,
                                                )
                                            "
                                            close
                                        >
                                            {{ item.title }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        @click="saveCaps"
                        :disabled="
                            !validateTime(resetAt) ||
                            !resetAt ||
                            !cap ||
                            cap < 0 ||
                            !interval ||
                            !timezone ||
                            !type ||
                            !(
                                selectedPublishers.length ||
                                selectedAdvertisers.length ||
                                selectedCountry.length
                            )
                        "
                    >
                        {{ editId === null ? "Create" : "Save" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Modal -->
        <!-- Edit Modal -->
        <!-- <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent />
        </div>
        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="createModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div> -->
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, reactive } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
// import axios from "axios";
// import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import CreateComponent from "./CreateComponent.vue";
// import EditComponent from "./EditComponent.vue";
import {
    fetchFilterData,
    fetchCaps,
    createCaps,
    updateCaps,
    deleteCaps,
    fetchTimezones,
} from "./service";
import { useStore } from "vuex";
import { usePermissions } from "@/composables/usePermissions";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
        // CreateComponent,
        // EditComponent,
    },
    // props: {
    //     filterData: {
    //         type: Object,
    //         default: () => ({}),
    //     },
    // },

    setup() {
        const { hasPermission } = usePermissions();
        const store = useStore();
        const searchTable = ref("");
        const params = reactive({
            current_page: 1,
            pagesize: 100,
        });
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            params.pagesize = value;
        }
        const filteredRows = computed(() => {
            if (!searchTable.value?.trim()) {
                return rows.value;
            }
            return rows.value.filter((row) => {
                const {
                    interval,
                    type,
                    countries,
                    filled,
                    affiliates,
                    advertisers,
                    timezone,
                } = row;
                if (
                    interval
                        .toLowerCase()
                        .includes(searchTable.value.toLowerCase()) ||
                    type
                        .toLowerCase()
                        .includes(searchTable.value.toLowerCase()) ||
                    filled
                        .toString()
                        .includes(searchTable.value.toLowerCase()) ||
                    countries.some((country) =>
                        country.name
                            .toLowerCase()
                            .includes(searchTable.value.toLowerCase()),
                    ) ||
                    advertisers.some((advertiser) =>
                        advertiser.display_name
                            .toLowerCase()
                            .includes(searchTable.value.toLowerCase()),
                    ) ||
                    affiliates.some((affiliate) =>
                        affiliate.name
                            .toLowerCase()
                            .includes(searchTable.value.toLowerCase()),
                    ) ||
                    timezone.name
                        .toLowerCase()
                        .includes(searchTable.value.toLowerCase())
                ) {
                    return true;
                }
                return false;
            });
        });
        // Interval	Type Filled	Countries	Advertiser	Publisher	Reset At	Timezone	Action
        const allColumns = ref([
            {
                field: "interval",
                title: "Interval",
                visible: true,
                sortable: true,
            },
            {
                field: "type",
                title: "Type",
                visible: true,
                sortable: true,
            },
            {
                field: "filled",
                title: "Filled",
                visible: true,
                sortable: true,
            },
            {
                field: "countries",
                title: "Countries",
                visible: true,
                sortable: false,
            },
            {
                field: "advertisers",
                title: "Advertiser",
                visible: true,
                sortable: false,
            },
            {
                field: "affiliates",
                title: "Publisher",
                visible: true,
                sortable: false,
            },
            {
                field: "reset_time",
                title: "Reset At",
                visible: true,
                sortable: false,
            },
            {
                field: "timezone",
                title: "Timezone",
                visible: true,
                sortable: false,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
                sortable: false,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const interval = ref("");
        const dialog = ref(false);
        const editId = ref(null);
        const type = ref("");
        const resetAt = ref(null);
        const resetAtMenu = ref(false);
        const timezone = ref("");
        const timezones = ref([]);
        const cap = ref(0);
        const countries = computed(() => store.getters.getCountries);
        const selectedCountry = ref([]);
        const publishers = ref([]);
        const selectedPublishers = ref([]);
        const advertisers = ref([]);
        const selectedAdvertisers = ref([]);

        const progress = ref(0);

        function validateTime(time) {
            console.log("validateTime", time);
            if (!time) {
                return false;
            }
            const timeParts = time.split(":");
            if (timeParts.length !== 2) {
                return false;
            }
            const hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1]);
            if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
                return false;
            }
            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                return true;
            }
            return false;
        }

        function calculateProgress(filled, cap) {
            if (cap === 0) {
                return 100;
            }

            return Math.round((filled / cap) * 100);
        }

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        // const editModal = ref(null);
        // const createModal = ref(null);

        async function editCapsRow(id) {
            console.log("Accept Lead: " + id);
            // enable editId
            editId.value = id;
            // autofill form
            const selectedRowData = rows.value.find((row) => row.id === id);
            selectedPublishers.value = selectedRowData.affiliates;
            selectedAdvertisers.value = selectedRowData.advertisers;
            selectedCountry.value = selectedRowData.countries.map(
                (obj) => obj.id,
            );
            interval.value = selectedRowData.interval;
            type.value = selectedRowData.type;
            //  00:00:00 -> 00:00
            resetAt.value =
                selectedRowData.reset_time.split(":")[0] +
                ":" +
                selectedRowData.reset_time.split(":")[1];
            timezone.value = selectedRowData.timezone.id;
            cap.value = selectedRowData.cap;
            // show modal
            dialog.value = true;
        }

        async function createRow() {
            // fetch fill the autocomplete
            editId.value = null;
            selectedPublishers.value = [];
            selectedAdvertisers.value = [];
            selectedCountry.value = [];
            interval.value = "";
            type.value = "";
            resetAt.value = null;
            timezone.value = "";
            cap.value = 0;
            // show modal
            dialog.value = true;
        }

        async function saveCaps() {
            // console.log("Save Caps start");
            // console.log(editId.value);
            // console.log(selectedPublishers.value);
            // console.log(selectedAdvertisers.value);
            // console.log(selectedCountry.value);
            // console.log(interval.value);
            // console.log(type.value);
            // console.log(resetAt.value);
            // console.log(timezone.value);
            // console.log(cap.value);
            // console.log("Save Caps end");
            const data = {
                advertiser_ids: selectedAdvertisers.value.map((obj) => obj.id),
                type: type.value,
                interval: interval.value,
                cap: String(cap.value),
                timezone_id: timezone.value,
                country_ids: selectedCountry.value,
                affiliate_ids: selectedPublishers.value.map((obj) => obj.id),
                reset_time: resetAt.value || "00:00",
            };

            try {
                if (editId.value !== null) {
                    // edit cap
                    data.id = editId.value;
                    console.log("Edit Cap payload", data);
                    const response = await updateCaps(data);
                    if (!response) {
                        throw new Error("Failed to update");
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Successfully updated",
                        text: "Cap updated successfully",
                    });
                    console.log("Edit Cap", response);
                } else {
                    console.log("Create Cap payload", data);
                    const response = await createCaps(data);
                    if (!response) {
                        throw new Error("Failed to create");
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Successfully created",
                        text: "Cap created successfully",
                    });
                    console.log("Create Cap", response);
                }
            } catch (error) {
                console.error("Failed to save Cap:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to save Cap. Please try again.",
                });
            } finally {
                dialog.value = false;
            }
        }

        function deleteCapsRow(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // Make the POST request with axios
                        const data = await deleteCaps(id);
                        if (!data) {
                            throw new Error("Failed to delete");
                        }
                        Swal.fire({
                            icon: "success",
                            title: "Successfully deleted",
                            text: data.message,
                        });
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });

                        console.error("Failed to delete:", error);
                    }
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            rows.value = (await fetchCaps()) || [];
            timezones.value = (await fetchTimezones()) || [];
            publishers.value = (await fetchFilterData()).publishers || [];
            advertisers.value = (await fetchFilterData()).advertisers || [];

            document.addEventListener("click", handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            searchTable,
            hasPermission,
            rows,
            params,
            page,
            handlePageChange,
            handlePageSizeChange,
            allColumns,
            visibleColumns,
            filteredRows,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            editCapsRow,
            deleteCapsRow,
            createCaps,
            dialog,
            editId,
            saveCaps,
            createRow,
            progress,
            calculateProgress,
            type,
            interval,
            resetAt,
            resetAtMenu,
            timezone,
            timezones,
            cap,
            countries,
            selectedCountry,
            publishers,
            selectedPublishers,
            advertisers,
            selectedAdvertisers,
            validateTime,
        };
    },
};
</script>

<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>

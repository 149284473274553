<template>
    <ContentLayout>
        <template #filter>
            <FilterComponent class="elevation-2" @applyFilters="handleApplyFilters" />
        </template>
        <template #default>
            <DataTableComponent :filterData="currentFilterData"  />
        </template>
    </ContentLayout>
</template>
<script>
import ContentLayout from "../layout/ContentLayout.vue";

import FilterComponent from "../components/distributions/FilterComponent.vue";
import DataTableComponent from "../components/distributions/DataTableComponent.vue";

export default {
    name: "DistributionsView",
    components: {
        ContentLayout,
        FilterComponent,
        DataTableComponent,
    },
    data() {
        return {
            currentFilterData: {},
            showFilter: true,
        };
    },
    methods: {
        handleApplyFilters(filterData) {
            console.log(filterData);
            this.currentFilterData = filterData;
        },
    },
};
</script>

<style scoped></style>

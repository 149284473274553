/* eslint-disable no-unused-vars */
export default class ReportPermission {

    constructor() {
        // Initial setup for report permission
        this.permissions_list = {
            "global": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 93},
                    { "name": "payout", "permission_id": 94},
                    { "name": "profit", "permission_id": 95},
                    { "name": "ecpl", "permission_id": 96},
                    { "name": "aecpl", "permission_id": 97},
                    { "name": "margin", "permission_id": 98},
                ]
            },
            "advertiser_id": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 99},
                    { "name": "payout", "permission_id": 100},
                    { "name": "profit", "permission_id": 101},
                    { "name": "ecpl", "permission_id": 102},
                    { "name": "aecpl", "permission_id": 103},
                    { "name": "margin", "permission_id": 104},
                ]
            },
            "country_id": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 105},
                    { "name": "payout", "permission_id": 106},
                    { "name": "profit", "permission_id": 107},
                    { "name": "ecpl", "permission_id": 108},
                    { "name": "aecpl", "permission_id": 109},
                    { "name": "margin", "permission_id": 110},
                ]
            },
            "offer": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 111},
                    { "name": "payout", "permission_id": 112},
                    { "name": "profit", "permission_id": 113},
                    { "name": "ecpl", "permission_id": 114},
                    { "name": "aecpl", "permission_id": 115},
                    { "name": "margin", "permission_id": 116},
                ]
            },
            "affiliate_id": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 117},
                    { "name": "payout", "permission_id": 118},
                    { "name": "profit", "permission_id": 119},
                    { "name": "ecpl", "permission_id": 120},
                    { "name": "aecpl", "permission_id": 121},
                    { "name": "margin", "permission_id": 122},
                ]
            },
            "created_at": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 123},
                    { "name": "payout", "permission_id": 124},
                    { "name": "profit", "permission_id": 125},
                    { "name": "ecpl", "permission_id": 126},
                    { "name": "aecpl", "permission_id": 127},
                    { "name": "margin", "permission_id": 128},
                ]
            },
            "brand_status": {
                sub_permission: [
                    { "name": "revenue", "permission_id": 129},
                    { "name": "payout", "permission_id": 130},
                    { "name": "profit", "permission_id": 131},
                    { "name": "ecpl", "permission_id": 132},
                    { "name": "aecpl", "permission_id": 133},
                    { "name": "margin", "permission_id": 134},
                ]
            }
        }
    }

    hasPermissionToShow = (tab, module, storePermissions) => {

        const within_permission = this.permissions_list[tab].sub_permission.find( (item) => item.name == module.toLowerCase())
        if ( typeof within_permission === 'undefined' ) {
            return true
        } else {
            return storePermissions.includes(within_permission.permission_id)
        }
    }

}

<template>
    <div class="bh-table-responsive table-container mb-5 mt-2">
        <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-4">
            <v-text-field
                label="Search blacklisted item"
                v-model="search_blacklist_item"
                hide-details
                variant="outlined"
                clearable
                density="compact"/>
        </v-col>
        <v-data-table
            :headers="blacklist_service.blackList.setupHeaders()"
            :items="blacklist_computed"
            :search="search_blacklist_item"
            density="compact"
            hover>
            <template v-slot:[`item.expires_at`]="{ item }">
                <span>{{ item.expires_at == "1970-01-01" ? "" : new Date(item.expires_at).toLocaleDateString() }}</span>
            </template>
            <template v-slot:[`item.hide_blocked`]="{ item }">
                <span v-if="item.hide_blocked == 1">On</span>
                <span v-else>Off</span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{ new Date(item.updated_at).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon density="compact" variant="text" @click="blacklist_methods.methods().openBlacklistItemModelForEdit(item)">
                    <v-icon class="mx-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon density="compact" variant="text" @click="blacklist_methods.methods().deleteBlacklistItem(item.id)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script setup>
import { computed, onMounted, defineEmits, ref } from "vue"

/** Service Layer */
import BlacklistService from "../modules/blacklist.service";
import BlockListMethods from "../modules/blacklist.methods";

const emits = defineEmits(['editBlacklistItem'])

const blacklist_service = new BlacklistService()
const blacklist_methods = new BlockListMethods(emits)

/** Local variables */
const search_blacklist_item = ref("")
const blacklist_items = ref()

/** Computed Variables */
const blacklist_computed = computed( () => {
    return blacklist_items.value
})

onMounted( async () => {
    const result = await blacklist_service.blackList.getBlacklistItems()
    if ( result.success == "success" ) {
        blacklist_items.value = result.data
    }
})

</script>

<style scoped>
::v-deep .v-data-table__th{
    vertical-align: middle !important;
    height: 50px !important;
}
::v-deep .v-data-table__td {
    height: 75px !important;
    cursor: pointer;
}
</style>


export default class CapsService {

    constructor(params) {
        this.params = params
    }

    /** Methods */
    methods = ( () => {

        const setPageSizeDefault = (page_size) => {
            const page_size_default = {
                page: page_size
            }
            localStorage.setItem("pagesize", JSON.stringify(page_size_default))
        }

        const getPageSizeDefault = () => {
            const page_size_default = JSON.parse(localStorage.getItem("pagesize"))
            console.log(page_size_default)
            return page_size_default?.page == null ? 30 : page_size_default?.page
        }

        return {
            setPageSizeDefault,
            getPageSizeDefault
        }
    })()

}

<template>
    <div>
        <!-- For Text Field -->
         <div v-if="selected_blacklist_type?.field_type == 'v-text-field' && selected_blacklist_type?.name != 'IP Range'">
            <label>{{ selected_blacklist_type.name }}</label>
            <v-text-field
                variant="outlined"
                :placeholder="JSON.parse(selected_blacklist_type?.config).placeholder"
                v-model="customInputField" @update:model-value="methods.getCustomInputFieldValue"
                :rules="[(v) => !!v || 'You are required to fill this ' + selected_blacklist_type.name + '.']"
                class="mb-3">
            </v-text-field>
        </div>

        <!-- Countries -->
         <div v-if="selected_blacklist_type?.field_type == 'v-select' && selected_blacklist_type.name == 'Country'">
            <v-autocomplete
                variant="outlined"
                :placeholder="JSON.parse(selected_blacklist_type?.config).placeholder"
                :label="JSON.parse(selected_blacklist_type?.config).label"
                v-model="customInputField" @update:model-value="methods.getCustomInputFieldValue"
                :rules="[(v) => !!v || 'You are required to fill this ' + selected_blacklist_type.name + '.']"
                :items="getCountries"
                item-title="name"
                item-value="id"
                clearable
                return-object
                class="mb-3">
            </v-autocomplete>
        </div>
        <!-- ./Countires -->

        <!-- Funnel Slugs -->
        <div v-if="selected_blacklist_type?.field_type == 'v-select' && selected_blacklist_type.name == 'Funnel Slug'">
            <v-autocomplete
                variant="outlined"
                :placeholder="JSON.parse(selected_blacklist_type?.config).placeholder"
                :label="JSON.parse(selected_blacklist_type?.config).label"
                v-model="customInputField" @update:model-value="methods.getCustomInputFieldValue"
                :rules="[(v) => !!v || 'You are required to fill this ' + selected_blacklist_type.name + '.']"
                :items="getFunnelSlugs"
                item-title="funnel"
                item-value="funnel"
                clearable
                return-object
                class="mb-3">
            </v-autocomplete>
        </div>
        <!-- ./Funnel Slugs -->

        <!-- IP Range -->
        <div v-if="selected_blacklist_type?.field_type == 'v-text-field' && selected_blacklist_type.name == 'IP Range'">
            <label>IP Address From</label>
            <v-text-field
                v-model="ip_range.from" @update:model-value="methods.getCustomInputFieldValue"
                variant="outlined"
                :placeholder="JSON.parse(selected_blacklist_type.config).placeholder">
            </v-text-field>
            <label>IP Address To</label>
            <v-text-field
                v-model="ip_range.to" @update:model-value="methods.getCustomInputFieldValue"
                variant="outlined"
                :placeholder="JSON.parse(selected_blacklist_type.config).placeholder">
            </v-text-field>
        </div>
        <!-- ./IP Range-->
    </div>
</template>

<script setup>
import { watch, ref, onMounted, computed } from "vue"
import { defineProps, defineEmits } from "vue"

/** Blacklist Class - Countries */
import BlacklistService from "../../modules/blacklist.service"
const blacklist_service = new BlacklistService()

const props = defineProps(['selectedBlacklistType', "customInputField"])
const emits = defineEmits(['getCustomInputField'])

const selected_blacklist_type = ref()
const customInputField = ref({})
const countries = ref([])
const ip_range = ref({
    from: '',
    to: ''
})
const funnel_slugs = ref()

const methods = (() => {
    const getCustomInputFieldValue = () => {
        if ( selected_blacklist_type.value.name == 'Country' ) {
            emits("getCustomInputField", customInputField.value?.name)
        } else if (selected_blacklist_type.value.name == 'Funnel Slug') {
            emits("getCustomInputField", customInputField.value?.funnel)
        } else if (selected_blacklist_type.value.name == 'IP Range') {
            if ( ip_range.value.from != '' && ip_range.value.to != '' ) {
                emits("getCustomInputField", ip_range.value.from + "-" + ip_range.value.to)
            }
        } else {
            emits("getCustomInputField", customInputField.value)
        }
    }


    return {
        getCustomInputFieldValue
    }
})()

const getCountries = computed( () => {
    return countries.value
})

const getFunnelSlugs = computed( () => {
    return funnel_slugs.value
})

onMounted( async () => {
    // Only for default setting
    selected_blacklist_type.value = props.selectedBlacklistType
    if ( selected_blacklist_type.value.name != 'IP Range' ) {
        customInputField.value = props.customInputField
    } else {
        // IP Range
        const iprange = String(props.customInputField).split("-")
        ip_range.value.from = iprange[0]
        ip_range.value.to = iprange[1]
    }
    countries.value = await blacklist_service.blackList.getAllCountries()
    funnel_slugs.value = await blacklist_service.blacklistTypes.getAllFunnelSlugs()
})

watch( props, () => {
    selected_blacklist_type.value = props.selectedBlacklistType
})

</script>

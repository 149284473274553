import axios from "axios";

/** Blacklist Management Service */
export default class BlacklistService {

    constructor() {
    }

    /**
     * Blacklist Types
     */
    blacklistTypes = (() => {

        const getAllBlacklistTypes = async () => {
            const response = await axios.get(`/api/blacklist/blacklist-types/get-all`)
            return response.data
        }

        const getAllFunnelSlugs = async () => {
            const response = await axios.get('/api/blacklist/blacklist-types/get-funnel-slugs')
            return response.data.data
        }

        return {
            getAllBlacklistTypes,
            getAllFunnelSlugs
        }

    })()

    /**
     * Blacklist Functions
     */
    blackList = ( () => {

        /** Add New Blacklist Item */
        const addBlacklistItem = async (data) => {
            const response = await axios.post(`/api/blacklist/add-new-item`, data)
            return response.data
        }

        /** Get All Blacklist Items */
        const getBlacklistItems = async () => {
            const response = await axios.get(`/api/blacklist/get-all-items`)
            return response.data
        }

        /** Delete Blacklist Item */
        const deleteBlacklistItem = async (id) => {
            const response = await axios.delete(`/api/blacklist/delete-item/${id}`)
            return response.data
        }

        const editBlacklistItem = async (id, data) => {
            const response = await axios.post(`/api/blacklist/edit-item/${id}`, data)
            return response.data
        }

        /** Table Header */
        const setupHeaders = () => {
            return [
                {
                    align: 'start',
                    key: 'id',
                    sortable: true,
                    title: 'ID',
                    width: "5%"
                },
                {
                    align: 'center',
                    key: 'get_blacklist_type.name',
                    sortable: true,
                    title: 'Type',
                    width: "10%"
                },
                {
                    align: 'start',
                    key: 'blacklist_value',
                    sortable: true,
                    title: 'Value',
                    width: "10%"
                },
                {
                    align: 'center',
                    key: 'expires_at',
                    sortable: true,
                    title: 'Expires At',
                    width: "10%"
                },
                {
                    align: 'center',
                    key: 'hide_blocked',
                    sortable: true,
                    title: 'Hide Blocked',
                    width: "10%"
                },
                {
                    align: 'start',
                    key: 'description',
                    sortable: true,
                    title: 'Description',
                    width: "20%"
                },
                {
                    align: 'center',
                    key: 'created_at',
                    sortable: true,
                    title: 'Created At',
                    width: "10%"
                },
                {
                    align: 'center',
                    key: 'updated_at',
                    sortable: true,
                    title: 'Updated At',
                    width: "10%"
                },
                {
                    align: 'center',
                    key: 'actions',
                    sortable: true,
                    title: 'Actions',
                    width: "15%"
                }
            ]
        }

        /** List of Countries */
        const getAllCountries = async () => {
            const response = await axios.get('/api/countries')
            return response.data
        }

        return {
            addBlacklistItem,
            getBlacklistItems,
            setupHeaders,
            deleteBlacklistItem,
            editBlacklistItem,
            getAllCountries
        }
    })()

    /**
     * Blacklist Log Functions
     */
    blackListLogs = ( () => {

    })()
}

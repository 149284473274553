<template>
    <v-row class="my-2">
        <div class="col-12 d-flex justify-end gap-2 mb-4">
            <StandardBtn type="create"  
                    :text="true" 
                    custom-icon="mdi-filter"
                    custom-color="indigo-darken-1"
                    >
                Set Filter
            </StandardBtn>
            <StandardBtn type="create" 
                    :text="true" 
                    custom-icon="mdi-filter"
                    custom-color="indigo-darken-1">
                Add Widget
            </StandardBtn>
        </div>
        <div
            class="d-flex flex-column col-12 col-md-7 gap-4"
            v-if="hasPermission('PENDING_CONVERSIONS_LIST')"
        >
            <div>
                <MainOverview />
            </div>
            <div>
                <LatestFTDs />
            </div>
        </div>
        <div class="d-flex flex-column col-12 col-md-5 gap-4">
            <div v-if="hasPermission('PUBLISHERS_LIST')">
                <TopAffiliate />
            </div>
            <div v-if="hasPermission('ADVERTISERS_LIST')">
                <TopBrand />
            </div>
        </div>
    </v-row>
</template>

<script>
import MainOverview from "../components/dashboard/MainOverview.vue";
import TopAffiliate from "../components/dashboard/TopAffiliate.vue";
import TopBrand from "../components/dashboard/TopBrand.vue";
import LatestFTDs from "../components/dashboard/LatestFTDs.vue";
import { usePermissions } from "@/composables/usePermissions.js";
export default {
    components: {
        MainOverview,
        TopAffiliate,
        TopBrand,
        LatestFTDs,
    },
    setup() {
        const { hasPermission } = usePermissions();
        return {
            hasPermission,
        };
    },
};
</script>

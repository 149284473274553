<template>
    <div>
        <v-dialog v-model="add_blacklist_model" max-width="800px" persistent>
            <v-form ref="blacklist_form">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add New Blacklist</span>
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            label="Blacklist Types"
                            v-model="selected_blacklist_type"
                            variant="outlined"
                            :items="getBlacklistTypes"
                            item-title="name"
                            return-object></v-select>

                        <!-- Condition Showing of Field based on type of blocklist -->
                        <CustomInputField
                            :selectedBlacklistType="selected_blacklist_type"
                            :customInputField="custom_input_field"
                            @getCustomInputField="methods.getCustomeInputValue"></CustomInputField>
                        <!-- /Condition Showing of Field based on type of blocklist -->

                        <v-date-input v-model="expires_at"
                            label="Expires At"
                            prepend-icon=""
                            variant="outlined"
                            persistent-placeholder
                            append-inner-icon="mdi-calendar"></v-date-input>

                        <v-checkbox label="Hide blacklisted Leads"
                            v-model="hide_blacklisted_leads"
                            variant="outlined"
                            hide-details></v-checkbox>
                        <!--<label class="hide-blacklisted-leads">When this rule is matched, it makes the lead not visible in admin, like it was deleted.</label>-->

                        <v-textarea model="description"
                            label="Describe why blacklist item is set"
                            variant="outlined"
                            v-model="description"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="blocklist_methods.methods().closeAddBlocklistModal()">Cancel</v-btn>
                        <v-btn color="blue darken-1" v-if="is_save == true" @click="blocklist_methods.methods().saveBlacklistItem({
                            blacklist_type_id: selected_blacklist_type.id,
                            expires_at: expires_at,
                            hide_blocked: hide_blacklisted_leads,
                            description: description,
                            blacklist_value: custom_input_field
                        }, blacklist_form)">
                            Save
                        </v-btn>
                        <v-btn color="blue darken-1" v-else @click="blocklist_methods.methods().updateBlacklistItem(id, {
                            blacklist_type_id: selected_blacklist_type.id,
                            expires_at: expires_at,
                            hide_blocked: hide_blacklisted_leads,
                            description: description,
                            blacklist_value: custom_input_field
                        })">
                            Edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"
import { defineProps, defineEmits } from "vue";

/** Extended Components */
import CustomInputField from "./extendcomponents/CustomInputField.vue";

/** Props and Emits */
const props = defineProps(['add_blacklist_dialog', 'is_save', 'data_for_edit'])
const emits = defineEmits(['closeAddBlackListModal'])

/** Classes for Methods and Computed */
import BlockListMethods from "../modules/blacklist.methods";
import BlacklistService from "../modules/blacklist.service";

const blocklist_methods = new BlockListMethods(emits)
const blacklist_service = new BlacklistService()

/** All local variables */
const add_blacklist_model = ref(false)
const blacklist_types = ref()
const expires_at = ref()
const hide_blacklisted_leads = ref(false)
const selected_blacklist_type = ref()
const is_save = ref(true)
const description = ref()
const custom_input_field = ref()
const id = ref()
const blacklist_form = ref(null)

const getBlacklistTypes = computed( () => {
    return blacklist_types.value
})

onMounted( async () => {
    const result = await blacklist_service.blacklistTypes.getAllBlacklistTypes()
    if ( result.success == "success" ) {
        blacklist_types.value = result.data
        selected_blacklist_type.value = blacklist_types.value[0]
    }
})

/** Custom Methods */
const methods = ( () => {
    const getCustomeInputValue = (value) => {
        custom_input_field.value = value
    }
    return {
        getCustomeInputValue
    }
})()
/** ./Custom Methods */

watch( props, () => {
    add_blacklist_model.value = props.add_blacklist_dialog
    is_save.value = props.is_save
    if ( props.is_save == false ) {
        selected_blacklist_type.value = props.data_for_edit.get_blacklist_type
        expires_at.value = props.data_for_edit.expires_at == "1970-01-01" ? null : new Date(props.data_for_edit.expires_at)
        hide_blacklisted_leads.value = props.data_for_edit.hide_blocked == 1 ? true : false
        description.value = props.data_for_edit.description
        custom_input_field.value = props.data_for_edit.blacklist_value
        id.value = props.data_for_edit.id
    } else {
        selected_blacklist_type.value = blacklist_types.value[0]
        expires_at.value = null
        hide_blacklisted_leads.value = false
        description.value = null
        custom_input_field.value = null
        id.value = null
    }
})

</script>

<style scoped>
.hide-blacklisted-leads {
    font-size: 10px !important;
}
</style>

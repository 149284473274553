
export default class PageSizeService {

    constructor() {
        this.config = [
            { module: "publisher-caps", pagesize: 30 },
            { module: "advertiser-caps", pagesize: 30 },
            { module: "publisher-main", pagesize: 30 },
            { module: "advertiser-main", pagesize: 30 }
        ]

        /** Condition to check if there is an existing records */
        const default_config = JSON.parse(localStorage.getItem('pagesize-default'))
        if ( !default_config ) {
            localStorage.setItem('pagesize-default', JSON.stringify(this.config))
        } else {
            this.config = default_config
        }
    }

    methods = ( () => {

        const setPageSizeDefault = (module_name, pagesize) => {
            this.savePageSizeToLocalStorage(module_name, pagesize)
        }

        const getPageSizeDefault = (module_name) => {
            return this.config.find( (c) => c.module == module_name)
        }

        return {
            setPageSizeDefault,
            getPageSizeDefault
        }

    })()

    /** Private Methods/Functions */
    savePageSizeToLocalStorage = (module_name, page_size) => {
        this.config.find( (c) => {
            if ( c.module == module_name ) {
                c.pagesize = page_size
            }
        })
        localStorage.setItem('pagesize-default', JSON.stringify(this.config))
    }

    getPageSizeDefaultConfigByModule = (module_name) => {
        return this.config.find((c) => c.module == module_name)
    }
}

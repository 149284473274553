<template>
    <div class="container-fluid">
        <!-- Top Sub Navigation -->
        <ul class="nav justify-content-end">
            <li class="nav-item">
                <StandardBtn type="create"
                    @click="viewActions().showAddBlackListModal()"></StandardBtn>
            </li>
        </ul>
        <!-- Blacklisted Display Components -->
        <BlacklistDataTable @editBlacklistItem="viewActions().editBlacklistItem"></BlacklistDataTable>

        <!-- Modal Components -->
        <AddBlacklistModal
            :add_blacklist_dialog=add_blacklist_dialog
            :is_save="is_save_blacklist"
            :data_for_edit="item_for_edit"
            @closeAddBlackListModal="viewActions().closeAddBlackListModal()"></AddBlacklistModal>
    </div>
</template>

<script setup>
import { ref } from "vue"
import AddBlacklistModal from '@/components/blacklist/subcomponents/AddBlacklistModal.vue';
import BlacklistDataTable from "@/components/blacklist/subcomponents/BlacklistDataTable.vue";

const add_blacklist_dialog = ref(false)
const is_save_blacklist = ref(true)
const item_for_edit = ref()

const viewActions = () => {

    /** Action for showing and closing Add Blocklist Add Modal Dialog */
    const showAddBlackListModal = () => {
        is_save_blacklist.value = true
        add_blacklist_dialog.value = true
    }
    const closeAddBlackListModal = () => {
        add_blacklist_dialog.value = false
    }
    /** ./Action for showing and closing Add Blocklist Add Modal Dialog */

    const editBlacklistItem = (data) => {
        is_save_blacklist.value = false
        add_blacklist_dialog.value = true
        item_for_edit.value = data
    }

    return {
        showAddBlackListModal,
        closeAddBlackListModal,
        editBlacklistItem
    }
}
</script>

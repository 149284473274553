<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editModalLabel">Create Caps</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="handleSubmit">
                    <p class="pb-2">General Information</p>

                    <!-- Type Field -->
                    <div class="mb-3">
                        <label for="type" class="form-label">Type</label>
                        <select
                            class="form-select"
                            id="type"
                            v-model="form.type"
                            required
                        >
                            <option value="lead">Lead</option>
                            <option value="ftd">FTD</option>
                        </select>
                    </div>

                    <!-- Interval Field -->
                    <div class="mb-3">
                        <label for="interval" class="form-label"
                            >Interval</label
                        >
                        <select
                            class="form-select"
                            id="interval"
                            v-model="form.interval"
                            required
                        >
                            <option value="daily">Daily</option>
                            <option value="total">Total</option>
                        </select>
                    </div>

                    <!-- Reset Time Field -->
                    <div class="mb-3">
                        <label for="reset_time" class="form-label"
                            >Reset Time</label
                        >
                        <input
                            type="time"
                            class="form-control"
                            id="reset_time"
                            v-model="form.reset_time"
                            required
                        />
                    </div>

                    <!-- Timezone Field with Select2 -->
                    <div class="mb-3">
                        <label for="timezone" class="form-label"
                            >Timezone</label
                        >
                        <select
                            class="form-select select2"
                            id="timezone"
                            v-model="form.timezone"
                            required
                        >
                            <option value="" disabled>Select Timezone</option>
                            <option
                                v-for="timezone in timezones"
                                :key="timezone.id"
                                :value="timezone.value"
                            >
                                {{ timezone.text }}
                            </option>
                        </select>
                    </div>

                    <!-- Cap Field -->
                    <div class="mb-3">
                        <label for="cap" class="form-label">Cap (number)</label>
                        <input
                            type="number"
                            class="form-control"
                            id="cap"
                            v-model="form.cap"
                            required
                        />
                    </div>
                    <!-- Country Select with Select2 -->
                    <div class="mb-3">
                        <label for="country" class="form-label">Country</label>
                        <select
                            class="form-select select2"
                            id="country"
                            v-model="form.country"
                            required
                        >
                            <option value="" disabled>Select Country</option>
                            <option
                                v-for="country in countries"
                                :key="country.id"
                                :value="country.name"
                                :data-icon="country.country_icon"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="handleSubmit"
                >
                    Save changes
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import "select2/dist/css/select2.min.css";
import $ from "jquery";
import "select2";
import { useStore } from "vuex";

const store = useStore();

// Form reactive state
const form = ref({
    country: "",
    amount: "",
    cap: "",
    type: "",
    interval: "",
    reset_time: "",
    timezone: "",
});

const countries = computed(() => store.getters.getCountries);
//const userSetting = computed(() => store.getters.getUserSettings)

const timezones = ref([
    {
        id: "pst",
        value: "America/Los_Angeles",
        text: "Pacific Time (PT)",
    },
    { id: "mst", value: "America/Denver", text: "Mountain Time (MT)" },
    { id: "cst", value: "America/Chicago", text: "Central Time (CT)" },
    { id: "est", value: "America/New_York", text: "Eastern Time (ET)" },
    // Add more timezones as needed
]);

// Fetch country data from the API
onMounted(async () => {
    try {
        // Initialize Select2 after data is loaded
        initializeSelect2();
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
});

// Initialize Select2
function initializeSelect2() {
    $(document).ready(function () {
        $(".select2").select2({
            width: "100%",
            templateResult: formatCountryOption,
            templateSelection: formatCountryOption,
        });
    });
}

// Format country option for Select2
function formatCountryOption(option) {
    if (!option.id) {
        return option.text;
    }
    const $option = $(
        `<span>${option.text} <img src="${option.element.dataset.icon}" class="flag-icon" style="width: 20px; height: 15px;" /></span>`,
    );
    return $option;
}

// Method to handle form submission
function handleSubmit() {
    console.log("Form Data:", form.value);
    // Add your form submission logic here
}
</script>

<style scoped>
.flag-icon {
    vertical-align: middle;
    margin-left: 5px;
}
</style>

const Permissions = {
    REPORTS_LIST: 1,
    STATUS_GROUPS_LIST: 2,
    STATUS_GROUPS_EDIT: 3,
    STATUS_GROUPS_DELETE: 4,
    STATUS_GROUPS_CREATE: 5,
    CAPS_LIST: 6,
    CAPS_EDIT: 7,
    CAPS_DELETE: 8,
    CAPS_CREATE: 9,
    USERS_LIST: 10,
    USERS_EDIT: 11,
    USERS_DELETE: 12,
    USERS_CREATE: 13,
    PUBLISHERS_LIST: 14,
    PUBLISHERS_EDIT: 15,
    PUBLISHERS_DELETE: 16,
    PUBLISHERS_CREATE: 17,
    PUBLISHER_DISTRIBUTIONS_LIST: 18, // copy from DISTRIBUTIONS_LIST
    PUBLISHER_CAPS_LIST: 63,
    PUBLISHER_CAPS_EDIT: 64,
    PUBLISHER_CAPS_DELETE: 65,
    PUBLISHER_CAPS_CREATE: 66,
    PUBLISHER_SUPPORTED_COUNTRIES_LIST: 67,
    PUBLISHER_SUPPORTED_COUNTRIES_EDIT: 68, // not found
    PUBLISHER_SUPPORTED_COUNTRIES_DELETE: 69,
    PUBLISHER_SUPPORTED_COUNTRIES_CREATE: 70,
    PUBLISHER_PAYOUTS_LIST: 71,
    PUBLISHER_PAYOUTS_EDIT: 72,
    PUBLISHER_PAYOUTS_DELETE: 73,
    PUBLISHER_PAYOUTS_CREATE: 74,
    PUBLISHER_OVERRIDE_PUBLISHER_ID_LIST: 75,
    PUBLISHER_OVERRIDE_PUBLISHER_ID_EDIT: 76,
    PUBLISHER_OVERRIDE_PUBLISHER_ID_DELETE: 77,
    PUBLISHER_OVERRIDE_PUBLISHER_ID_CREATE: 78,
    PUBLISHER_OVERRIDE_API_OFFERS_LIST: 79,
    PUBLISHER_OVERRIDE_API_OFFERS_EDIT: 80,
    PUBLISHER_OVERRIDE_API_OFFERS_DELETE: 81,
    PUBLISHER_OVERRIDE_API_OFFERS_CREATE: 82,
    DISTRIBUTIONS_LIST: 18,
    DISTRIBUTIONS_EDIT: 19,
    DISTRIBUTIONS_CREATE: 20,
    DISTRIBUTIONS_DELETE: 21,
    PENDING_CONVERSIONS_LIST: 22,
    PENDING_CONVERSIONS_MARK_FTD: 23,
    LEADS_LIST: 24,
    LEADS_MARK_TEST_LEAD: 25,
    LEADS_EDIT: 26,
    LEADS_MARK_FTD: 27,
    LEADS_INJECTION: 28,
    LEADS_OVERRIDE_STATUS: 29,
    LEADS_PROGRESS_LOGS: 30,
    ADVERTISERS_LIST: 31,
    ADVERTISERS_EDIT: 32,
    ADVERTISERS_DELETE: 33,
    ADVERTISERS_CREATE: 34,
    ADVERTISER_DISTRIBUTIONS_LIST: 18, // copy from DISTRIBUTIONS_LIST
    ADVERTISER_CAPS_LIST: 35,
    ADVERTISER_CAPS_EDIT: 36,
    ADVERTISER_CAPS_DELETE: 37,
    ADVERTISER_CAPS_CREATE: 38,
    ADVERTISER_OPENING_HOURS_LIST: 39,
    ADVERTISER_OPENING_HOURS_EDIT: 40,
    ADVERTISER_OPENING_HOURS_DELETE: 41,
    ADVERTISER_OPENING_HOURS_CREATE: 42,
    ADVERTISER_SUPPORTED_COUNTRIES_LIST: 43,
    ADVERTISER_SUPPORTED_COUNTRIES_EDIT: 44, // not found
    ADVERTISER_SUPPORTED_COUNTRIES_DELETE: 45,
    ADVERTISER_SUPPORTED_COUNTRIES_CREATE: 46,
    ADVERTISER_REVENUES_LIST: 47,
    ADVERTISER_REVENUES_EDIT: 48,
    ADVERTISER_REVENUES_DELETE: 49,
    ADVERTISER_REVENUES_CREATE: 50,
    ADVERTISER_EXCLUSIONS_LIST: 51,
    ADVERTISER_EXCLUSIONS_EDIT: 52,
    ADVERTISER_EXCLUSIONS_DELETE: 53,
    ADVERTISER_EXCLUSIONS_CREATE: 54,
    ADVERTISER_OVERRIDE_PUBLISHER_ID_LIST: 55,
    ADVERTISER_OVERRIDE_PUBLISHER_ID_EDIT: 56,
    ADVERTISER_OVERRIDE_PUBLISHER_ID_DELETE: 57,
    ADVERTISER_OVERRIDE_PUBLISHER_ID_CREATE: 58,
    ADVERTISER_OVERRIDE_API_OFFERS_LIST: 59,
    ADVERTISER_OVERRIDE_API_OFFERS_EDIT: 60,
    ADVERTISER_OVERRIDE_API_OFFERS_DELETE: 61,
    ADVERTISER_OVERRIDE_API_OFFERS_CREATE: 62,
    SETTINGS_LIST: 83,
    CLICK_REPORTS_LIST: 84,
    OFFERS_LIST: 85,
    CAMPAIGNS_LIST: 86,
    ROLES_LIST: 87,
    ROLES_EDIT: 88,
    ROLES_DELETE: 89,
    ROLES_CREATE: 90,
    BLOCKLIST_LIST: 91,
    REPORT_GLOBAL_REVENUE: 93,
    REPORT_GLOBAL_PAYOUT: 94,
    REPORT_GLOBAL_PROFIT: 95,
    REPORT_GLOBAL_ECPL: 96,
    REPORT_GLOBAL_AECPL: 97
};

export default Permissions;

<template>
    <v-row>
        <v-col md="12">
            <div class="col-md-12">
                <div class="mt-4 mb-4 pb-2 pt-5">
                    <nav aria-label="breadcrumb">
                        <ol class="box-shadow breadcrumb bg-light p-4">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'DashboardView' }"
                                    >Home</router-link
                                >
                            </li>
                            <li
                                class="breadcrumb-item active"
                                aria-current="page"
                            >
                                <router-link :to="{ name: 'SettingsView' }"
                                    >Settings</router-link
                                >
                            </li>
                        </ol>
                    </nav>
                </div>
                <!-- Tabs Navigation -->

                <div class="box-shadow p-4">
                    <form @submit.prevent="submitForm">
                        <div class="row mb-3">
                            <div class="col-md-12 mb-4">
                                <label for="status-select" class="form-label"
                                    >GENERAL
                                </label>
                            </div>

                            <v-checkbox
                                v-model="allowNewFtdNotification"
                                label="Allow new FTD notification"
                            ></v-checkbox>

                            <div class="col-md-6">
                                <v-combobox
                                    label="Exclude FTD Status"
                                    v-model="selectedStatuses"
                                    :items="statusOptions"
                                    item-title="status"
                                    item-value="status"
                                    multiple
                                    variant="outlined"
                                    density="compact"
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <v-chip
                                            small
                                            @click="
                                                selectedStatuses.splice(
                                                    index,
                                                    1,
                                                )
                                            "
                                            close
                                        >
                                            {{ item.title }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </div>
                            <div class="col-md-6">
                                <v-text-field
                                    variant="outlined"
                                    density="compact"
                                    label="Default Publisher Status"
                                    v-model="defaultPublisherStatus"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    class="mr-2"
                                    variant="outlined"
                                >
                                    Save
                                </v-btn>
                                <!-- <v-btn
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    @click="resetForm"
                                >
                                    Cancel
                                </v-btn> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { ref, watch, computed ,onMounted} from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useStore } from "vuex";

export default {
    name: "SettingsView",

    setup() {
        const store = useStore()
        const selectedStatuses = ref([]);
        const statusOptions = ref([]);

        const defaultPublisherStatus = ref("");
        const allowNewFtdNotification= ref(false);

        const userSettings = computed(()=>store.getters.getUserSettings) 
        const user = computed(()=>store.getters.getUser) 

        watch(()=>userSettings, userSettingsValue=>{
            if(userSettingsValue.value?.extras?.allow_new_ftd_notification){
                allowNewFtdNotification.value = userSettingsValue.value.extras.allow_new_ftd_notification
            }
        },{immediate:true, deep:true})

        onMounted(async () => {
            const response = await axios.get("/api/settings");
            selectedStatuses.value = response.data.suggestions.map(
                (item) => item.status,
            );
            defaultPublisherStatus.value =
                response.data.default_publisher_status;
        });


        

        const submitForm = async () => {
            try {
                await axios.post("/api/settings", {
                    exclude_ftd_status: selectedStatuses.value,
                    // .map(
                    //     (obj) => obj?.status,
                    // ),
                    default_publisher_status: defaultPublisherStatus.value,
                });

                await axios.put("/api/user-settings",{
                    user_id: user.value.user_id,
                    extras: {
                        allow_new_ftd_notification: allowNewFtdNotification.value,
                    }
                })

                await store.dispatch('fetchUserSettings')

                Swal.fire({
                    title: "Success",
                    text: "Settings updated successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                // resetForm();
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "Error",
                    text: "Error updating settings",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        };

        const resetForm = () => {
            selectedStatuses.value = [];
            defaultPublisherStatus.value = "";
        };

        return {
            selectedStatuses,
            statusOptions,
            submitForm,
            resetForm,
            defaultPublisherStatus,
            allowNewFtdNotification
            
        };
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
